import React from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IEvent } from 'videokube-front-library/dist/types';
import { CleverFormattedMessage, useFile, } from 'videokube-front-library';
import { Container, Typography, useMediaQuery } from '@material-ui/core';
import {Divider} from 'videokube-front-library/dist/Divider/Divider';
import { CounterBox } from './CounterBox';
import { counterBoxStyle, DividerLeftStyle, DividerTableStyle } from '../theme';

const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
  createStyles({
    scheduleTable: {
      marginBottom: '4em',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '9em',
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    scheduleBox: {
      backgroundSize: 'cover',
    },
    scheduleText: {
      paddingTop: '1em',
      paddingBottom: '1.1em',
      [theme.breakpoints.down('xs')]: {
        paddingInlineStart: '1em',
        paddingTop: '2em',
        paddingBottom: '0.1em',
      },
    },
    eventScheduleTitle: {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '0.9em',
      width: '110px',
      [theme.breakpoints.up('sm')]: {
        width: '400px',
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: '0.8em',
      },
    },
    eventScheduleDate: {
      color: "#fff",
      fontSize: "0.9em",
      fontWeight: 'bold',
      [theme.breakpoints.up('sm')]: {
        fontSize: '115%',
      },
    },
    eventScheduleTime: {
      color: "#fff",
      fontSize: "0.9em",
      fontStyle: 'italic',
      [theme.breakpoints.up('sm')]: {
        fontSize: '115%',
      },
    },
    liveCard: {
      background: 'linear-gradient(#000000bf, #0000001c, transparent)',
      padding: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #454545',
      [theme.breakpoints.down('sm')]: {
        padding: '7px',
      },
    },
    linkImage: {
      width: '25%',
      filter: 'drop-shadow(5px 8px 6px #000)',
      [theme.breakpoints.up('sm')]: {
        width: '13%',
      },
    },
    infoDiv: {
      width: '80%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    },
    ...extendsStyle
  }),
);

interface displayEventProps {
  eventsList: IEvent[],
  style?: any,
}

export const DisplayEvent = (props : displayEventProps) => {
  let styleNew = props.style || {};
  const theme = useTheme();
  const classes = useStyles(styleNew)();
  let eventsList = props.eventsList;
  const header = useFile("categories_header");

  let sizeBackround = "?size=original"
  if (useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true })){
    sizeBackround = "?size=medium"
  }
  return <React.Fragment>
    <Container maxWidth='lg' id="schedule" key="displayEvent" className={classes.scheduleTable} >
      <Typography component="h2" variant="h3" className={classes.scheduleText}>
        <CleverFormattedMessage id="live_schedule_title" />
        <Divider style={DividerLeftStyle(theme)}/>
      </Typography>
      <CounterBox style={counterBoxStyle(theme)}/>
      <div className={classes.scheduleBox} style={{ backgroundImage: `url(${header.file+sizeBackround})` }}>
        {eventsList.map((event, key) => (
          <div key={key} className={classes.liveCard} >
            <Link className={classes.linkImage} to={`/category/${event.category_slug}`}>
              <img width="100%" src={event.category_title + "?size=small"} alt={event.category_slug} />
            </Link>
            <div className={classes.infoDiv}>
              <Typography component="h3" variant="h3" className='eventTitleP' >
                <CleverFormattedMessage className={classes.eventScheduleTitle} id={event.description} />
              </Typography>
              <Divider style={DividerTableStyle(theme)}/>
              <div className={classes.eventScheduleDate} >{moment(event.startTime).format('DD/MM')}</div>
              <Divider style={DividerTableStyle(theme)}/>
              <div className={classes.eventScheduleTime} >{moment(event.startTime).format('HH:mm')}</div>
            </div>
          </div>
        ))}
      </div>
    </Container>
  </React.Fragment>

}