import React from 'react';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
//import Grid from '@material-ui/core/Grid';
import { CleverFormattedMessage, HeaderImage, useFile, useLibWrapper } from 'videokube-front-library';
import { Container, Typography } from '@material-ui/core';
import { useIntl } from "react-intl";
import { DividerCenterStyle, responsiveHeaderImage } from '../../theme';
import Button from "@material-ui/core/Button";
import AccountCircle from "@material-ui/icons/Person";
import {Divider} from 'videokube-front-library/dist/Divider/Divider';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: '20vh',
            flexGrow: 1,
            paddinBottom: "40px",
        },
        accountInfo: {
            height: '65vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            [theme.breakpoints.down("sm")]: {
                height: '55vh',
                minHeight: '440px',
            },
        },
        textAccount: {
            margin: "8px",
            marginInlineStart: "0",
            display: 'flex',
            alignItems: 'center',
        },
        textUnsub: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        divider: {
            height: '2px',
            backgroundColor: theme.palette.primary.main,
            width: "60%",
            margin: "auto",
        },
        bottomButton: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        link: {
            color: theme.palette.primary.main,
            width: '320px',
        }

    }));

export function Account() {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const theme = useTheme();
    const userInfo = useLibWrapper();
    //const history = useHistory();
    const headerImage = useFile("account_header");

    const accountTitle = formatMessage({ id: 'account_title' });
    //const usernameLabel = formatMessage({ id: 'login_icon' });
    const accountSubtitle = formatMessage({ id: 'account_subtitle' });
    const subscriptionStatusLabel = formatMessage({ id: 'subscription_status_label' });
    const subscriptionExpirationDateLabel = formatMessage({ id: 'subscription_expiration_date_label' });
    const subscriptionCancellationDateLabel = formatMessage({ id: 'subscription_cancellation_date_label' });
    const logoutLabel = formatMessage({ id: 'logout_label' });
    //const subscriptionLabel = formatMessage({id: 'subscription_label'});

    let header = <HeaderImage title={accountTitle} style={responsiveHeaderImage(theme)} color={theme.palette.secondary.main} />
    if (headerImage) {
        header = <HeaderImage title={accountTitle} style={responsiveHeaderImage(theme)} img={headerImage.file + "?size=original"} />;
    }

    const customLogout = () => {
        localStorage.removeItem("msisdn");
        userInfo.logout()
    }

    return <div className={classes.root}>
        {header}
        <Divider style={DividerCenterStyle(theme)}/>
        <Container maxWidth="md" className={classes.accountInfo} >
            {/*  <Grid container justify="center">
                <Grid item key={"account_info"} xs={12} className={classes.accountInfo}> */}
            <div>
            <Typography className={classes.textAccount} component="h4" variant="body1">{accountSubtitle}</Typography>
            <Typography className={classes.textAccount} component="h6" variant="body1"><AccountCircle/> {userInfo.getUsername()}</Typography>
            <Typography className={classes.textAccount} component="h6" variant="body1">{subscriptionStatusLabel} : {userInfo.getLabelSubscription()}</Typography>
            {userInfo.getIsActiveUser() && (
                <Typography className={classes.textAccount}  component="h6" variant="body1">{subscriptionExpirationDateLabel} :  {userInfo.getSubscriptionExpirationDate()}</Typography>
            )}
            {userInfo.getIsActiveUser() && userInfo.getSubscriptionCancellationDate() && (
                <Typography className={classes.textAccount}  component="h6" variant="body1">{subscriptionCancellationDateLabel} : {userInfo.getSubscriptionCancellationDate()}</Typography>
            )}
            {/* {!userInfo.getIsActiveUser() && <Button className={classes.link} onClick={() => history.push("/landing")}>{subscriptionLabel}</Button>} */}
            </div>
            <div className={classes.bottomButton}>
            <Typography className={classes.textUnsub} component="h6" variant="body1"><CleverFormattedMessage id="unsubscribe_label" /></Typography>
            <Button className={classes.link} variant="outlined" color="primary" onClick={customLogout}>{logoutLabel}</Button>
            {/* </Grid>
            </Grid> */}</div>
        </Container>
    </div>
};
