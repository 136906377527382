import React, {useRef, useState} from 'react';
import { useHistory } from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {CleverFormattedMessage, useFile, useLibWrapper, videokubeUtils} from 'videokube-front-library';
import {Button, InputAdornment, TextField} from '@material-ui/core';
import {useIntl} from "react-intl";
import Container from '@material-ui/core/Container';
import {useSnackbar} from 'material-ui-snackbar-provider';
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#000000", // @todo get background color from configuration in back-end
      height: "100vh",
      '& a': {
        fontWeight: 'bold',
        color: theme.palette.primary.main
      },
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    paper: {
      paddingTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      '& input': {
        backgroundColor: "#FFFFFF"
      }
    },
    button: {
      height: '60px',
    },
    forgotPassword: {
      marginTop: "16px"
    },
    logo: {
      width: "100%",
      marginTop: "5vh",
      marginBottom: "5vh",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0vh",
        marginBottom: "0vh",

      }
    },
    link: {
      color: theme.palette.primary.main,
      width: '100%',
    },
    loginDescription: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: '2rem',
      [theme.breakpoints.down("sm")]: {
        fontSize: '1rem'
      }
    },
    textField:{
      height: '60px',
      marginBottom: "10px",
      backgroundColor: "#FFFFFF",
      '& .MuiInput-root': {
        height: "100%",
        paddingLeft: "5px",
      }
    }
}));

export function LoginPageCore() {
    const classes = useStyles();
    const history = useHistory();
    const country = videokubeUtils.getCountry();
    const countryPhone = country ? '+' + country.phone : '';
    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [forgetPassword, setForgetPassword] = useState(false);

    const recaptchaRef = useRef<ReCAPTCHA | null>(null);
    const { formatMessage } = useIntl();
    const userInfo = useLibWrapper();
    const snackbar = useSnackbar();
    const needUsername = formatMessage({id: "need_username"} )
    const needPassword = formatMessage({id: 'need_password'});
    const wrongPhoneNumber = formatMessage({id: "wrong_phone_number"} )
    const usernameLabel = formatMessage({id: 'login_label_username'});
    const passwordLabel = formatMessage({id: 'login_label_password'});

    const normalizeUsernameWithIndicatif = () => {
      if(username && country){
        return username.includes(country.phone) ? username : country.phone + username;
      }
      return '';
    }

    const onSubmitCore = async () => {
      if(!username){
        return snackbar.showMessage(needUsername);
      }

      if (!videokubeUtils.checkUsernameForNumbersFromCountry(username)) {
          return snackbar.showMessage(wrongPhoneNumber);
      }

      if(!password){
        snackbar.showMessage(needPassword);
        return;
      }

      let user = normalizeUsernameWithIndicatif();

      userInfo.login(user, password);
      return;

    }

    const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
      event.preventDefault();
      if(forgetPassword){
        await sendNewPassword();
        return;
      }
      onSubmitCore();
    };

    const sendNewPassword = async () => {
      if(!username){
          return snackbar.showMessage(needUsername);
      }
      if (!videokubeUtils.checkUsernameForNumbersFromCountry(username)) {
        return snackbar.showMessage(wrongPhoneNumber);
      }
      const captchaError = formatMessage({id: 'captcha_error'});

      const recaptcha =  await recaptchaRef?.current?.executeAsync();

      if(!recaptcha){
          return snackbar.showMessage(captchaError);
      }
      let user = normalizeUsernameWithIndicatif();
      await userInfo.forgetPassword(user, recaptcha);
      setForgetPassword(false);
      return;
    }

    const logo = useFile("logo");
    if(!logo){
      return (<div>Logo not found</div>)
    }

    if(videokubeUtils.isDvMode() || userInfo.getUsername()){
      history.push("/");
      return null;
    }

    return (
      <div className={classes.root} >
        <Container component="main" maxWidth="sm">
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={videokubeUtils.getCaptchaSiteKey()}
          />
          <div className={classes.paper}>
            <img className={classes.logo} src={logo.file} alt="Logo" />

            <React.Fragment>
            {forgetPassword && (
            <CleverFormattedMessage
              id="landingpage_forget_password_title"
              className={classes.loginDescription}
            />
            )}
            {!forgetPassword && (
              <CleverFormattedMessage
                id="landingpage_already_customer"
                className={classes.loginDescription}
              />
            )}
            <form className={classes.form} noValidate onSubmit={onSubmit}>
              <Grid container justify="center">
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    placeholder={usernameLabel}
                    autoFocus
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    className={classes.textField}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: <InputAdornment position="start">{countryPhone}</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={12} md={6}>
                  {!forgetPassword && (
                    <TextField
                      variant="standard"
                      required
                      fullWidth
                      placeholder={passwordLabel}
                      className={classes.textField}
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  )}
                </Grid>
              </Grid>
              {!forgetPassword && (
                <Grid container justify="center">
                  <Grid item xs={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                    >
                      <CleverFormattedMessage id="landingpage_login_bottom" />
                    </Button>
                  </Grid>
                </Grid>
              )}
              {forgetPassword && (
                <Grid container justify="center">
                  <Grid item xs={12} md={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                    >
                      <CleverFormattedMessage id="landingpage_forget_password_bottom" />
                    </Button>
                  </Grid>
                </Grid>
              )}
              <div>
              { !forgetPassword && (
                <Button
                  onClick={() => setForgetPassword(!forgetPassword)}
                  className={classes.link}
                >
                  <CleverFormattedMessage
                    id={'landingpage_forget_password'}
                  />
                </Button>
              )}
              </div>
            </form>
            </React.Fragment>
          </div>
        </Container>
      </div>
    );
}

export const LoginPage = () => {
  return <LoginPageCore/>
}
