import React, { useContext } from "react";
import { Typography, makeStyles, Theme, createStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { CleverFormattedMessage } from 'videokube-front-library/dist/Texts/CleverFormattedMessage';
import CloseIcon from '@material-ui/icons/Close';
import { PlaylistContext } from "../playlistContext";
import { MediaLight } from "./AddToPlaylist";
import { DurationFormat } from "videokube-front-library/dist/utils";
import { useIntl } from "react-intl";

export interface PlaylistCardProps {
  mediaLight: MediaLight;
  designCard?: any;
}

const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
  createStyles({
    playlistCard: {
      padding: '10px',
      paddingInlineStart: '0px',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid #454545',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 0px',
      },
    },
    img: {
      maxWidth: "90%",
      maxHeight: "250px",
      width: '11%',
      margin: 'auto 0px',
      filter: 'saturate(1.2)',
      [theme.breakpoints.down("xs")]: {
        height: '85px',
        width: 'auto',
      },
    },
    infoDiv: {
      padding: '0px 20px',
      width: '65%',
      [theme.breakpoints.down("md")]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0px 10px',
      },
    },
    text: {
      lineHeight: '1.3',
    },
    closeButton: {
      '&.MuiButton-root': {
        minWidth: 'auto',
        alignItems: 'initial',
      },
      '&.MuiButton-text': {
        padding: '0px',
      },
    },
    closeIcon: {
      color: 'white',
    },
    ...extendsStyle
  })
);

export function PlaylistCard(props: PlaylistCardProps) {
  const classes = useStyles(props.designCard || {})();
  const {toggleMediaInPlaylist} = useContext(PlaylistContext);
  const { formatMessage } = useIntl();

  return (
    <div
      className={classes.playlistCard}
      key={props.mediaLight.slug}
      >
      <Link to={"/vod/" + props.mediaLight.slug} style={{ display: 'flex', width: '90%', }} >
        <img
          alt={props.mediaLight.title}
          src={props.mediaLight.preview}
          title={props.mediaLight.title}
          className={classes.img}
        />

        <div className={classes.infoDiv}>
          {props.mediaLight.description && (
            <Typography variant="subtitle1" component="h2" className={classes.text} >
              <CleverFormattedMessage id={props.mediaLight.title} />
            </Typography>
          )}
          <Typography variant="body2">{DurationFormat(props.mediaLight.duration, formatMessage)}</Typography>
        </div>
      </Link>
      <Button
        className={classes.closeButton}
        onClick={() => toggleMediaInPlaylist(props.mediaLight)}
      >
        <CloseIcon className={classes.closeIcon}/>
      </Button>
    </div>
  );
}

