import React from 'react';
import { Button, Grid, Typography, useTheme} from '@material-ui/core';
import { CleverFormattedMessage } from 'videokube-front-library';
import { counterComponent } from '../theme';
import { makeStyles, Theme, createStyles, } from '@material-ui/core/styles';
import moment from 'moment';
import { AppState } from '../store';
import { useSelector } from 'react-redux';
import { IEvent } from 'videokube-front-library/dist/types';
import { Counter } from 'videokube-front-library';
import { useHistory } from "react-router-dom";


const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
    createStyles({
        button: {
            fontWeight: 'bold',
            letterSpacing: '1.2px',
            boxShadow: 'rgb(0 0 0 / 49%) 2px 2px 7px 0px',
            margin: '15px auto',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.7rem',
                padding: '11px 13px',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '0.7rem',
                padding: '8px 5px',
            },
            [theme.breakpoints.up('sm')]: {
                minWidth: '200px',
            },
        },
        counterDiv: {
            textAlign: 'center',
            margin: '1em auto',
            maxWidth: '1240px',
            background: 'linear-gradient(#090909, #a0011b)',
            [theme.breakpoints.down('xs')]: {
                marginTop: '1em',
            },
        },
        numberPart: {
        },
        buttonPart: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        ...extendsStyle
    }),
);

export interface CounterBoxProps {
    style?: any;
  }

export const CounterBox = (props: CounterBoxProps) => {
    const theme = useTheme();
    const classes = useStyles(props.style || {})();
    let history = useHistory()
    const events = useSelector<AppState, IEvent[]>((state) => state.app.events);

    const liveEvent = events.find(
        anEvent => moment(anEvent.startTime).isAfter(moment().subtract(5, 'hour')) &&
            moment(anEvent.startTime).isBefore(moment())
    );
    const nextEvent = events.find(
        anEvent => moment(anEvent.startTime).isAfter(moment().subtract(5, 'hour'))
    );

    const homePage = window.location.pathname === '/' || window.location.pathname === '/tv';

    let Anchor = () => {
        history.push("/#schedule");
      };

    return (
            <Grid container justify="center" className={classes.counterDiv} >
                <Grid item lg={8} md={8} sm={8} xs={9} className={classes.numberPart} >
                    {!liveEvent && nextEvent && (
                        <Counter style={counterComponent(theme)} date={moment(nextEvent.startTime).toDate()} />
                    )}
                    {liveEvent && (
                        <Typography component="h3" variant="body1" >
                            <CleverFormattedMessage id="live_on_air" />
                        </Typography>
                    )}
                </Grid>
                {!homePage && !liveEvent && (
                <Grid item
                    lg={2} md={3} sm={3} xs={3} className={classes.buttonPart} >
                    <a href="#schedule">
                        <Button variant="contained" color="primary" className={classes.button}
                        onClick={Anchor}
                        >
                            <CleverFormattedMessage id="button_schedule" />
                        </Button>
                    </a>
                </Grid>
                )}
                {liveEvent && (
                    <Button variant="contained" color="primary" className={classes.button} href="/tv">
                        <CleverFormattedMessage id="button_live" />
                    </Button>
                )}
            </Grid>
    )
}