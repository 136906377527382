import React from 'react';
import { Typography, makeStyles, Theme, createStyles } from '@material-ui/core';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import 'swiper/swiper.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { Category } from 'videokube-front-library/dist/types';
import { CleverFormattedMessage } from 'videokube-front-library/dist/Texts/CleverFormattedMessage';

const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
  createStyles({
    swiper: {
        position: "unset",
        display: "flex",
        alignItems: "center",
    },
    containerSlider: {
      paddingLeft: "16px",
      paddingRight: "16px",
      [theme.breakpoints.down("sm")]: {
          paddingLeft: "unset",
          paddingRight: "unset",
      }
    },
    swiperSlide: {
      flexDirection: "column"
    },
    textDiv: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '1em',
      },
      [theme.breakpoints.up('sm')]: {
        paddingTop: '3em',
      },
    },
    title: {
      fontWeight: 500,
      letterSpacing: '0.1em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.6em',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.9em',
      },
    },
    description: {
      maxWidth: '600px',
    },
    ...extendsStyle
  }));

interface Slider {
  style?: any;
}

SwiperCore.use([Autoplay]);


export const SliderAbout = (props: Slider) => {
  const classes = useStyles(props.style || {})();

  const categories = useSelector<AppState,Category[]>(state => state.app.categories);
  const params = {
    autoplay: {
      delay: 2500,
      disableOnInteraction: true
    },
    spaceBetween: 6,
    loop: true,
    speed: 600,
  };

  return (
      <div style={{ position: 'relative' }}>
        <div className={classes.containerSlider}>
          <Swiper {...params}
                    className={classes.swiper} >

            {categories.map((slide, key) => {
              return (
                <React.Fragment>
                    <SwiperSlide key={slide.title} virtualIndex={key} className={classes.swiperSlide}>
                      <Link to={`/category/${slide.slug}`} key={slide.id}>
                        <Typography variant="h5" className={classes.title} >
                          <CleverFormattedMessage id={slide.name} />
                        </Typography>
                        <div className={classes.textDiv}>
                          <Typography component="div" variant="body1" className={classes.description} >
                            <CleverFormattedMessage id={slide.description} />
                          </Typography>
                        </div>
                        </Link>
                    </SwiperSlide>
                </React.Fragment>
              )
            })}
          </Swiper>
        </div>
      </div>
  );
}