import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Video,  CleverFormattedMessage, } from 'videokube-front-library';
import { useIntl } from "react-intl";
import { Container, Box, Grid, useTheme, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { api } from 'videokube-front-library';
import { AppState } from '../../store';
import { useSelector } from 'react-redux';
import { Media as IMedia, Category, VideoLink, JwtPayload } from "videokube-front-library/dist/types";
import { SingleVideoCard } from '../../components/SingleVideoCard';
import { counterBoxStyle, DividerCenterStyle, DividerLeftStyle, singleVideoCardInCategory, videoInfo } from '../../theme';
import {Divider} from 'videokube-front-library/dist/Divider/Divider';
import { CounterBox } from '../../components/CounterBox';
import { VideoInfo } from '../../components/VideoInfo';

const { getVideoLink, getIdWithSlug } = api;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingTop: '65px',
    },
    similarVideos: {
      marginBottom: '24px'
    },
    alert: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
    },
    counterVod: {
      textAlign: 'center',
      marginTop: '2em',
      [theme.breakpoints.up('md')]: {
        marginTop: '4em',
      },
    },
  }),
);

export function Vod() {
  const classes = useStyles();
  //const events = useSelector<AppState, IEvent[]>((state) => state.app.events);
  //let nextEvent = events.filter(d => new Date(d.startTime).getTime() - new Date().getTime() > 0);
  //TODO: we should avoid define "undefined" values. null values are OK
  const { formatMessage } = useIntl();
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoPreview, setVideoPreview] = useState<string>('');
  const [videoDescription, setVideoDescription] = useState<string | undefined>(undefined);
  const [video, setVideo] = useState<IMedia>();
  const [videos, setVideos] = useState<Array<IMedia>>([]);
  //const [keywords, setKeywords] = useState<Array<Keyword>>([]);
  const categories = useSelector<AppState, Category[]>(state => state.app.categories);

  const jwtPayload = useSelector<AppState, JwtPayload>((state) => state.app.jwtPayload);

  // const needLoginTitle = formatMessage({ id: 'need_login_title' });
  const number_of_related_videos =
    (useSelector<AppState>(
      (state) => state.app.interfaceConfiguration?.number_of_related_videos,
    ) as number) || 12;

  let { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    const getVideoIdWithSlug = async () => {
      if (!slug) {
        return;
      }
      const videosFromSlug = await getIdWithSlug(slug);
      if (!videosFromSlug) {
        return;
      }
      //TODO: why ever is this an array?
      let video = videosFromSlug[0];
      if (!video) {
        return;
      }
      //Why ever don't we use actualVideo.description ??!
      setVideoDescription(video.description);
      //setKeywords(video.keywords);

      // Search category
      const videoCateg = categories.find(category => category.medias.find(aVideo => aVideo.id === video.id));
      if (videoCateg) {
        // Find actual video
        const actualVideo = videoCateg.medias.find(cVideo => cVideo.slug === video.slug);
        setVideo(actualVideo);

        // Remove actual video
        const videos = videoCateg.medias.filter(bVideo => bVideo.slug !== video.slug);
        setVideos(videos);
      }
      //We reset values
      setVideoUrl(null);
      setVideoPreview("");
      let videoInfo: VideoLink;
      if (jwtPayload) {
        try {
          videoInfo = await getVideoLink(video.id);
        } catch (e) {
          return;
        }

        setVideoUrl(videoInfo.link);
        setVideoPreview(videoInfo.preview);
      }
    };

    getVideoIdWithSlug();
  }, [slug, categories, jwtPayload]);

  const theme = useTheme();

  return (
    <div className={classes.root}>
      {videoUrl && (
        <Container maxWidth="md">
          <Video video={videoUrl} poster={videoPreview} bigCenteredMode={true} />
        </Container>
      )}
      {/* TODO ca sert pas a grand chose ca  */}
      {/* {!jwtPayload && (
        <CustomSnackBar text={needLoginTitle} />
      )} */}
      <Container maxWidth="md">
        <Box display="flex" mt={2} mb={7}>
          {video && (
            <VideoInfo
              title={video.title}
              duration={video.duration}
              productionYear={video.productionYear || undefined}
              language={formatMessage({ id: 'video_language_eng' })}
              keywords={video.keywords}
              description={videoDescription}
              img={video.preview}
              style={videoInfo(theme)}/>
          )}
        </Box>
        <Box flexGrow={1}>
          <Typography component="h2" variant="h3" style={{ marginTop: '2em', }}  >
            <CleverFormattedMessage id="similar_videos" />
            <Divider style={DividerLeftStyle(theme)}/>
          </Typography>
          <Grid container spacing={3} className={classes.similarVideos}>
            {videos.slice(0, number_of_related_videos).map((video, i) => (
              <Grid key={i} item xs={6} md={3}>
                <SingleVideoCard height={230}
                  media={video}
                  backgroundMode
                  style={singleVideoCardInCategory(theme)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Typography className={classes.counterVod} component="h2" variant="h3" >
        <CleverFormattedMessage id="counter_title" />
      </Typography>
      <Divider style={DividerCenterStyle(theme)}/>
      <div style={{ margin: '1.2em auto 2em', maxWidth: '1090px', }} >
        <CounterBox style={counterBoxStyle(theme)}/>
      </div>
    </div>
  );
}
