import React, { useRef } from 'react';
import SwiperCore, { Virtual, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Media } from 'videokube-front-library/dist/types';
import { createStyles, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { FightCard } from './FightCard';
import 'swiper/swiper.scss';
import { useFile } from 'videokube-front-library';

const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
    createStyles({
        swiperDiv: {
        display: 'flex',
        overflow: 'hidden',
        maxWidth: '1230px',
        margin: '0px auto',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
            paddingBottom: '11em',
          [theme.breakpoints.only('sm')]: {
            paddingBottom: '6em',
          },
          [theme.breakpoints.between('sm', 'xs')]: {
            paddingBottom: '3em',
          },
          [theme.breakpoints.down('xs')]: {
            paddingBottom: '1em',
          },
        },
        swiper: {
            position: "unset",
            display: "flex",
            alignItems: "center",
            overflow: 'unset !important',
            marginTop: '2em',
            [theme.breakpoints.down('xs')]: {
                marginTop: '0.1em',
            },
        },
        arrowLeft: {
            paddingLeft: '30px',
            '&.swiper-button-disabled': {
                paddingLeft: 'unset',
            }
        },
        arrowRight: {
            paddingRight: '30px',
            '&.swiper-button-disabled': {
                paddingRight: 'unset',
            }
        },
        ...extendsStyle
    })
);


SwiperCore.use([Virtual, Navigation]);

interface SwiperMediaCategoryProps {
    medias?: Media[];
    path?: string;
    maxWidth?: number;
    slideWidht?: number;
    designCard?: any;
    style?: any,
};

interface SlidesPerView {
    slidesPerView: number
}

interface SlidesPerViewMap {
    [key: string]: SlidesPerView
}


export function SwiperMediaCategory(props: SwiperMediaCategoryProps) {
    const { medias, designCard } = props;
    let styleNew = props.style || {}
    let classes = useStyles(styleNew)();
    const navigationPrev = useRef<HTMLDivElement>(null);
    const navigationNext = useRef<HTMLDivElement>(null);
    let breakpoints: SlidesPerViewMap = {};

    const maxWidth = props.maxWidth || 4096;
    const slideWidht = props.slideWidht || 300;

    const generateBreakpoint = () => {
        // Size for 4k display 
        let nbSlide = maxWidth / slideWidht;

        for (let i = 1; i <= nbSlide; i++) {
            breakpoints[slideWidht * i] = { slidesPerView: i + 1 }
        }
        return breakpoints
    }

    breakpoints = generateBreakpoint();

    const params = {
        virtual: true,
        preloadImages: false,
        breakpoints: breakpoints,
        navigation: {
            prevEl: navigationPrev.current!, // Assert non-null
            nextEl: navigationNext.current!, // Assert non-null
        },
    };

    const picurePaper = useFile("paper_img_swiper");
    let sizeBackround = "?size=original"
    if (useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true })){
      sizeBackround = "?size=medium"
    }
    let picure = picurePaper.file + sizeBackround;

    return (
        <div style={{backgroundImage: `url(${picure})`}} className={classes.swiperDiv}>
            <div ref={navigationPrev} className={classes.arrowLeft} />
            <div style={{ width: 'calc(100% - 60px)' }}>
                <Swiper {...params}
                    className={classes.swiper}
                    onInit={swiper => {
                        if (swiper.params.navigation) {
                            const swiperNav = swiper.params.navigation as any;
                            swiperNav.prevEl = navigationPrev.current
                            swiperNav.nextEl = navigationNext.current
                        }
                        swiper.navigation.update()
                        swiper.update()
                    }}
                    onBreakpoint={swiper => {
                        swiper.update()
                    }}
                >
                    {medias && medias.map((media, index) => {
                        return (
                            <SwiperSlide key={media.id} virtualIndex={index}>
                                <FightCard
                                    media={media}
                                    designCard={designCard} />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            <div ref={navigationNext} className={classes.arrowRight} />
        </div>
    );
}