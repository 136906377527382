import React from "react";
import { Typography, makeStyles, Theme, createStyles, useTheme } from "@material-ui/core";
import { Link } from "react-router-dom";
import { CleverFormattedMessage } from 'videokube-front-library/dist/Texts/CleverFormattedMessage';
import { AddToPlaylistButton } from "./AddToPlaylist";
import { Media } from "videokube-front-library/dist/types";
import { AddToPlaylistStyle } from "../theme";

export interface FightCardProps {
  media:Media;
  designCard?: any;
}

export function FightCard(props: FightCardProps) {
  const theme = useTheme();
  const classes = useStyles(props.designCard || {})();
  return (
      <div className={classes.divPlayou} key={props.media.slug}>
        <Link to={"/vod/" + props.media.slug} >
          <img
            alt={props.media.title}
            src={props.media.preview + "?size=small"}
            title={props.media.title}
            className={classes.img}
          />
            <Typography variant="h6" component="h2" className={classes.text} >
              <CleverFormattedMessage id={props.media.title} />
            </Typography>
        </Link>
       <AddToPlaylistButton media={props.media} style={AddToPlaylistStyle(theme)}/>
      </div>
  );
}

const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
  createStyles({
    divPlayou: {
      position: 'relative',
      margin: '8px',
    },
    img: {
      maxWidth: "90%",
      maxHeight: "250px",
      boxShadow: '#000000a3 2px 3px 7px 3px',
      filter: 'saturate(1.2)',
    },
    text: {
      fontWeight: 'bold',
      padding: "0px",
      marginTop: "5px",
      fontSize: "10px",
      paddingBottom: '3px',
      textTransform: 'uppercase',
      maxWidth: '90%',
    },
    ...extendsStyle
  }
  )
);