import { createMuiTheme, Theme } from '@material-ui/core/styles';
import '@fontsource/montserrat/500.css';
import { InterfaceConfiguration } from 'videokube-front-library/dist/types';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const interfaceConfiguration = {
  id: 1,
  status: 'published',
  primary_color: '#001025',
  secondary_color: '#FFFFFF',
  number_of_items_per_columns: 4,
  number_items_in_category_preview: 4,
  number_of_related_videos: 8,
  video_sorting: 'string',
  background_color: "#FFFFFF"
};

const breakpoints = createBreakpoints({});

//export const getTheme = (interfaceConfigurations:InterfaceConfiguration) => {
export const getTheme = (interfaceConfiguration: InterfaceConfiguration) =>
  createMuiTheme({
    breakpoints,
    palette: {
      primary: {
        main: interfaceConfiguration.primary_color,
      },
      secondary: {
        main: interfaceConfiguration.secondary_color,
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
    overrides: {
      MuiCard: {
        root: {
          overflow: 'visible',
        },
      },
      MuiButton: {
        root: {
          fontWeight: 700,
          borderRadius: '0px',
          padding: '15px 25px',
          '&:hover': {
            backgroundColor: '!important',
          },
        },
      },
      MuiCardActionArea: {
        focusHighlight: {
          backgroundColor: 'white',
        },
      },
      MuiTypography: {
        // Title
        h1: {
          fontSize: '3em',
          textDecoration: 'none',
          color: 'white',
          textTransform: 'uppercase',
          textAlign: 'center',
          fontWeight: 400,
          letterSpacing: '1.2px',
          [breakpoints.down("sm")]: {
            fontSize: "2.5em"
          },
          [breakpoints.down("xs")]: {
            fontSize: "1.4em"
          },
        },
        // Title specific page
        h2: {
          fontSize: '2em',
          textDecoration: 'none',
          textAlign: 'center',
          color: 'white',
          fontWeight: 400,
          letterSpacing: '1.2px',
          textTransform: 'uppercase',
          [breakpoints.down("sm")]: {
            fontSize: "1.8em"
          },
          [breakpoints.down("xs")]: {
            fontSize: "1.3em"
          },
        },
        // Title section
        h3: {
          fontSize: '1.5em',
          textDecoration: 'none',
          color: 'white',
          letterSpacing: '1.2px',
          textTransform: 'uppercase',
          [breakpoints.down("sm")]: {
            fontSize: "1.7em"
          },
          [breakpoints.down("xs")]: {
            fontSize: "1em"
          },
        },
        // Homepage Section Two Title
        h4: {
          color: 'white',
          fontWeight: 'bold',
          fontSize: '1.55rem',
          textDecoration: 'none',
          letterSpacing: '1.2px',
          [breakpoints.down("sm")]: {
            fontSize: "1.5em"
          },
          [breakpoints.down("xs")]: {
            fontSize: "1em"
          },
        },
        h5: {
          fontSize: '1.1em',
          paddingTop: '8px',
          fontWeight: 'normal',
          lineHeight: '1',
          textDecoration: 'none',
          color: 'white',
          [breakpoints.down("xs")]: {
            fontSize: "0.8em",
            paddingTop: '6px',
          },
        },
        h6: {
          fontSize: '1.3em',
          fontWeight: 'normal',
          color: '#fff',
          lineHeight: '1',
          textDecoration: 'none',
          [breakpoints.down("xs")]: {
            fontSize: "1.1em",
            fontWeight: 'normal',
          },
        },
        body1: {
          fontSize: '1em',
          color: '#fff',
          textDecoration: 'none',
        },
        // SMALL TEXT in general
        body2: {
          fontSize: '0.7em',
          color: '#fff',
          marginTop: '2px',
          textDecoration: 'none',
        },
        // VOD Video Duration
        subtitle1: {
          fontSize: '0.9em',
          color: '#fff',
          textDecoration: 'none',
        },
        // Bold
        subtitle2: {
          fontSize: '1em',
          color: '#1c4a4e',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          lineHeight: '1',
          textDecoration: 'none',
        },
        // Duration
        overline: {
          fontSize: '0.91em',
          color: '#999999',
          lineHeight: '1',
          textDecoration: 'none',
        },
        // Type
        caption: {
          fontSize: '0.75em',
          opacity: '0.9',
          color: '#999999',
          textDecoration: 'none',
        },
      },
    },
  })
export const DefaultTheme = getTheme(interfaceConfiguration);

export const keywordCloud = (theme: Theme) => ({
  keywordBtnGeneral: {
    textTransform: 'capitalize',
    fontWeight: 'normal',
    color: 'white',
    border: '2px solid #10477e',
    margin: '6px',
  },
  keywordButton: {
  },
  keywordButtonHighlight: {
    backgroundColor: '#10477e !important',
  },
  showMoreBtn: {
    margin: '20px auto 0px',
    display: 'flex',
    color: 'white',
    fontWeight: 'normal',
    letterSpacing: '1.2px',
    fontSize: '0.8em',
  },
  keywordDiv: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingBottom: '2em',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '1em',
    },
  },
});

export const displayEvent = (theme: Theme) => ({
  scheduleTable: {
    marginBottom: '4em',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '9em',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  scheduleBox: {
    backgroundSize: 'cover',
  },
  scheduleText: {
    paddingTop: '1em',
    paddingBottom: '1.1em',
    [theme.breakpoints.down('xs')]: {
      paddingInlineStart: '1em',
      paddingTop: '2em',
      paddingBottom: '0.1em',
    },
  },
  eventScheduleTitle: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '0.9em',
    width: '110px',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.8em',
    },
  },
  eventScheduleDate: {
    color: "#fff",
    fontSize: "0.9em",
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      fontSize: '115%',
    },
  },
  eventScheduleTime: {
    color: "#fff",
    fontSize: "0.9em",
    fontStyle: 'italic',
    [theme.breakpoints.up('sm')]: {
      fontSize: '115%',
    },
  },
  liveCard: {
    background: 'linear-gradient(#000000bf, #0000001c, transparent)',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #454545',
    [theme.breakpoints.down('sm')]: {
      padding: '7px',
    },
  },
  linkImage: {
    width: '25%',
    filter: 'drop-shadow(5px 8px 6px #000)',
    [theme.breakpoints.up('sm')]: {
      width: '13%',
    },
  },
  infoDiv: {
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
})

export const VideoForYou = (theme: Theme) => ({
  randomVideoContainer: {
    marginTop: '16vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: '14vh',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '11vh',
    },
  },
  randomVideo: {
    marginTop: '3em',
    marginBottom: '1vh',
    [theme.breakpoints.only('sm')]: {
      marginTop: '5vh',
      marginBottom: '0vh',
      maxWidth: '90%',
      margin: '0px auto',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '1em',
      marginBottom: '0vh',
    },
  },
  randomVideoBackground: {
    maxWidth: '1240px',
    margin: '0px auto',
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '47em',
    marginBottom: '12vh',
    [theme.breakpoints.only('sm')]: {
      height: '630px',
      marginBottom: '12vh',
    },
    [theme.breakpoints.between('sm', 'xs')]: {
      height: '630px',
      marginBottom: '12vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: '310px',
      marginBottom: '7vh',
    },
  },
})

export const videoInfo = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    boxShadow: "none"
  },
  topSpacer: {
    marginTop: "5px"
  },
  keywords: {
    margin: "10px 0px",
  },
  keywordChip: {
    backgroundColor: 'transparent',
    color: '#438FC9',
  },
  imdbRate: {
    paddingLeft: '5px',
  },
  divImdbRate: {
    paddingTop: '5px',
    fontSize: '13px',
    borderRadius: '5px',
    padding: '3px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.dark,
  },
  description: {
  },
  time: {
    fontWeight: "bold"
  },
  columnTwo: {
    paddingInlineStart: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingInlineStart: "0px",
    }
  },
})

export const categoryGrid = (theme: Theme) => ({
  videoCategoryDiv: {
    width: "100%",
    background: 'linear-gradient( #001025, #438FC9, #001025)',
    padding: '2em 6px',
    marginBottom: '6em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '4em',
    },
  },
  categoryGrid: {
    [theme.breakpoints.up('xs')]: {
      marginTop: '2em',
    },
  },
  categName: {
    color: 'white',
    position: 'relative',
    zIndex: 2,
  },
  titleHolder: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    padding: '0px',
    color: 'white',
  },
  background: {
    height: '18vh',
    minHeight: '220px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    backgroundSize: 'cover !important',
    backgroundPosition: 'center !important',
    transition: 'all 0.2s',
    backfaceVisibility: 'hidden',
    [theme.breakpoints.down('md')]: {
      minHeight: '25vh',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '185px',
      boxShadow: '3px 3px 10px 3px rgb(0 0 0 / 52%)',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '13vh',
      height: 'auto',
    },
    boxShadow: '0 4px 12px -1.5px rgba(0, 0, 0, 0.3)',
    '&:hover': {
      boxShadow: '4px 2px 20px 7px rgb(0 0 0 / 82%)',
    },
  },
  videoGrid: {
    marginTop: '2em',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0em',
    },
  },
  buttonsShowMore: {
    display: 'flex',
    justifyContent: 'center',
  },
  showMoreBtn: {
    marginTop: '20px',
    color: 'white',
    fontWeight: 'normal',
    letterSpacing: '1.2px',
    fontSize: '0.8em',
  },
  backgroundHighlight: {
    height: '470px',
    minHeight: '30vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
    backgroundSize: 'cover !important',
    backgroundPosition: 'center !important',
    outline: "2px solid #438FC9",
    backfaceVisibility: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '300px',
      boxShadow: '3px 3px 10px 3px rgb(0 0 0 / 52%)',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '13vh',
      height: '160px',
    },
    boxShadow: '0 4px 12px -1.5px rgba(0, 0, 0, 0.3)',
    '&:hover': {
      boxShadow: '4px 2px 20px 7px rgb(0 0 0 / 82%)',
    },
  },
});

export const singleVideoCardInCategory = (theme: Theme) => ({
  card: {
    borderRadius: "0px",
    boxShadow: 'rgb(0 0 0 / 40%) 1px 4px 9px 3px',
    [theme.breakpoints.down('sm')]: {
    },
  },
  img: {
    height: 'auto !important',
    paddingBottom: '56.25%',
    [theme.breakpoints.between('sm', 'md')]: {
    },
    [theme.breakpoints.down('xs')]: {
    },
  },
  svgPlayer: {
    "&:hover": {
      "& svg": {
        opacity: 100,
        transition: "opacity 0.25s ease-in"
      }
    }
  },
  play: {
    position: "absolute",
    color: "white",
    fontSize: "5rem",
    bottom: 'calc(50% - 40px)',
    left: 'calc(50% - 40px)',
    [theme.breakpoints.down('md')]: {
      opacity: '80%',
      fontSize: "4rem",
      bottom: 'calc(50% - 33px)',
      left: 'calc(50% - 33px)',
    },
    [theme.breakpoints.up('md')]: {
      opacity: 0,
    },
  },
  textRight: {
    backgroundColor: '#090909cf',
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    padding: '0px 4px',
    [theme.breakpoints.up('md')]: {
      padding: '2px 5px',
    },
  },
  textLeft: {
    backgroundImage: 'linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0))',
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    padding: '4px',
    color: 'white',
    paddingBottom: '20px',
  }
});

export const DividerCenterStyle = (theme: Theme) => ({
  divider: {
    height: '2px',
    width: '30%',
    backgroundColor: '#D91535',
    margin: '12px auto',
    border: 'none',
    [theme.breakpoints.down("xs")]: {
        width: '50%',
        marginTop: '10px',
    },
  },
})

export const DividerFullStyle = (theme: Theme) => ({
  divider: {
    height: '2px',
    width: '100%',
    backgroundColor: '#D91535',
    margin: '12px auto',
    border: 'none',
    [theme.breakpoints.down("xs")]: {
        width: '100%',
        marginTop: '10px',
    },
  },
})

export const DividerLeftStyle = (theme: Theme) => ({
  divider: {
    height: '1px',
    width: '40%',
    backgroundColor: '#D91535',
    margin: '10px 0px',
    border: 'none',
    position: 'relative',
    zIndex: 4,
    [theme.breakpoints.down("xs")]: {
        width: '40%',
        marginTop: '10px',
    },
  },
})

export const DividerTableStyle = (theme: Theme) => ({
  divider: {
    height: '1px',
    width: '8%',
    backgroundColor: '#D91535',
    margin: '10px 0px 10px',
    border: 'none',
    [theme.breakpoints.only('sm')]: {
        //moibile horizontal, tablette vertical
        width: '2em',
    },
    [theme.breakpoints.down("xs")]: {
        width: '6%',
    },
    [theme.breakpoints.up("sm")]: {
        visibility: 'hidden',
    },
  },
})

export const PlaylistCardStyle = (theme: Theme) => ({
  playlistCard: {
    padding: '10px',
    paddingInlineStart: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #454545',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0px',
    },
  },
  img: {
    maxWidth: "90%",
    maxHeight: "250px",
    width: '11%',
    margin: 'auto 0px',
    filter: 'saturate(1.2)',
    [theme.breakpoints.down("xs")]: {
      height: '85px',
      width: 'auto',
    },
  },
  infoDiv: {
    padding: '0px 20px',
    width: '65%',
    [theme.breakpoints.down("md")]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '0px 10px',
    },
  },
  text: {
    lineHeight: '1.3',
  },
  closeButton: {
    '&.MuiButton-root': {
      minWidth: 'auto',
      alignItems: 'initial',
    },
    '&.MuiButton-text': {
      padding: '0px',
    },
  },
  closeIcon: {
    color: 'white',
  },
})

export const singleVideoCardInVideoForYou = (theme: Theme) => ({
  card: {
    boxShadow: "none",
    borderRadius: "0px",
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '930px',
      margin: '0px auto',
    },
  },
  img: {
    height: 'auto',
    boxShadow: 'rgb(0 0 0 / 50%) -60px 59px 20px 11px',
    filter: 'saturate(1.2)',
    [theme.breakpoints.only('sm')]: {
      height: '410px !important',
      maxHeight: '95vh',
    },
    [theme.breakpoints.between('sm', 'xs')]: {
      height: '420px !important',
    },
    [theme.breakpoints.down('xs')]: {
      height: '200px !important',
      boxShadow: 'rgb(0 0 0 / 40%) 1px 4px 9px 3px',
    },
  },
  svgPlayer: {
    "&:hover": {
      "& svg": {
        opacity: 100,
        transition: "opacity 0.25s ease-in"
      }
    }
  },
  play: {
    position: "absolute",
    color: "white",
    fontSize: "5rem",
    opacity: 0,
    bottom: 'calc(50% - 40px)',
    left: 'calc(50% - 40px)',
    [theme.breakpoints.down('md')]: {
      opacity: '30%',
    },
  },
  textRight: {
    backgroundColor: '#090909cf',
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    padding: '0px 4px',
    [theme.breakpoints.up('md')]: {
      padding: '2px 5px',
    },
  },
  textLeft: {
    backgroundImage: 'linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0))',
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    padding: '4px',
    color: 'white',
    paddingBottom: '20px',
  },
  videoName: {
    position: 'relative',
    fontWeight: '500',
    paddingTop: '10px',
    letterSpacing: '0.7px',
  },
});

export const swiperVideo = (theme: Theme) => ({
  swiperDiv: {
    display: 'flex',
    overflow: 'hidden',
    maxWidth: '1230px',
    margin: '0px auto',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
        paddingBottom: '11em',
      [theme.breakpoints.only('sm')]: {
        paddingBottom: '6em',
      },
      [theme.breakpoints.between('sm', 'xs')]: {
        paddingBottom: '3em',
      },
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '1em',
      },
    },
  swiper: {
    position: "unset",
    display: "flex",
    alignItems: "center",
    overflow: 'unset !important',
    marginTop: '2em',
    [theme.breakpoints.down('xs')]: {
        marginTop: '0.1em',
    },
},
arrowLeft: {
    paddingLeft: '30px',
    '&.swiper-button-disabled': {
        paddingLeft: 'unset',
    }
},
arrowRight: {
    paddingRight: '30px',
    '&.swiper-button-disabled': {
        paddingRight: 'unset',
    }
},
});

export const responsiveHeaderImage = (theme: Theme) => ({
  root: {
    alignItems: "flex-end",
    justifyContent: "center",
    display: "flex",
    backgroundColor: "transparent",
    height: "20vh",
    minHeight: '120px',
    [theme.breakpoints.down("sm")]: {
      height: "20vh",
    },
    [theme.breakpoints.up("xl")]: {
      height: "325px",
    }
  },
  text: {
    color: "white",
    textTransform: "uppercase",
    letterSpacing: "6px",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "1.4em",
    }
  },
});

export const seeAllStyleEndBlock = (theme: Theme) => ({
  seeAll: {
    textTransform: "uppercase",
    letterSpacing: "1px",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    fontSize: "10px",
    margin: "0px 0px 0px 0px",
  }
});

export const simpleCardNewStyle = (theme: Theme) => ({
  img: {
    borderRadius: "5px",
    objectFit: 'cover',
    objectPosition: 'top',
    position: 'absolute',
    top: '0',
    height: '100%',
  },
  play: {
    position: "absolute",
    color: "white",
    fontSize: "5rem",
    opacity: 0
  },
  root: {
    backgroundColor: theme.palette.secondary.main
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main
  },
  lazyHolder: {
    width: "100%",
    paddingTop: '56%',
    position: 'relative',
  },
});

export const simpleTextNewStyle = (theme: Theme) => ({
  text: {
    padding: '50px 16px',
    background: 'linear-gradient(#090909,#10477e, #090909)',
  },
  header: {
    root:{
      flexGrow: 1,
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      flexDirection: 'column',
      justifyContent: "center",
      alignItems: "center",
      marginTop: '60px',
      backgroundRepeat: "no-repeat",
      height: '30vh',
      backgroundColor: 'transparent',
      [theme.breakpoints.down("sm")]: {
        maxHeight: '300px',
        justifyContent: "center",
      },
      [theme.breakpoints.down("xs")]: {
        maxHeight: '190px',
      },
    },
    text: {
      color: "white",
      textTransform: "uppercase",
      letterSpacing: "10.8px",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: "1.4em",
        letterSpacing: "6px"
      },
    },
  }
});

export const counterBoxStyle = (theme: Theme) => ({
  button: {
    fontWeight: 'bold',
    letterSpacing: '1.2px',
    boxShadow: 'rgb(0 0 0 / 49%) 2px 2px 7px 0px',
    margin: '15px auto',
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.7rem',
        padding: '11px 13px',
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: '0.7rem',
        padding: '8px 5px',
    },
    [theme.breakpoints.up('sm')]: {
        minWidth: '200px',
    },
  },
  counterDiv: {
      textAlign: 'center',
      margin: '1em auto',
      maxWidth: '1240px',
      background: 'linear-gradient(#090909, #a0011b)',
      [theme.breakpoints.down('xs')]: {
          marginTop: '1em',
      },
  },
  numberPart: {
  },
  buttonPart: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
})

export const CustomSnackBarStyle = (theme: Theme) => ({
  alert:{
    backgroundColor: "#090909cc",
    color: '#FFFFFF',
  },
  alertLink: {
      color: '#FFFFFF',
  },
})

export const AddToPlaylistStyle = (theme: Theme) => ({
addToPlaylist: {
  color: 'white',
  padding: '0px',
  '&.MuiButton-root': {
      minWidth: 'auto',
  }
},
})

export const linkForgetPassword = (theme: Theme) => ({
  linkForgetPassword: {
    color: '#555555',
    paddingTop: '10px',
  }
});
export const navbarStyle = (theme: Theme) => ({
  logo: {
    width: 220,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 120
    }
  },
  button: {
    color: 'white',
    padding: '0px',
    '&:hover': {
      color: "#D9272E"
    },
  },
  buttonRight: {
    padding: '8px',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: "#D9272E",
    }
  },
  link: {
    color: 'white',
    margin: theme.spacing(2),
    zIndex: "1400",
    [theme.breakpoints.down("sm")]: {
    },
    '&:hover': {
      color: "#D9272E"
    }
  },
  activeButton: {
    color: "#D9272E",
  },
  dropdown: {
    zIndex: "1800 ! important",
    marginTop: "80px",
    maxHeight: 'calc(100%)',
    '& .MuiPaper-root': {
      top: '-16px !important',
      backgroundColor: "#D9272E"
    }
  },
  username: {
    [theme.breakpoints.up("sm")]: {
      order: 2,
      marginLeft: "2px"
    }
  },
  swiper: {
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    }
  },
})

export const fightCardDesign = (theme: Theme) => ({
divPlayou: {
  position: 'relative',
  margin: '8px',
},
divImage: {
  borderRadius: '5px',
  position: 'relative',
},
img: {
  maxWidth: "90%",
  maxHeight: "250px",
  boxShadow: '#000000a3 2px 3px 7px 3px',
  filter: 'saturate(1.2)',
},
lang: {
  top: '10px',
  position: 'absolute',
  right: '10px',
  backgroundColor: theme.palette.primary.main,
  padding: '3px 4px 4px',
  borderRadius: '2px',
},
text: {
  fontWeight: 'bold',
  padding: "0px",
  marginTop: "5px",
  fontSize: "10px",
  paddingBottom: '3px',
  textTransform: 'uppercase',
  maxWidth: "90%",
},
})

export const translationComponent = (theme: Theme) => ({
  dropdown: {
    marginTop: "80px",
    maxHeight: 'calc(100%)',
    '& .MuiPaper-root': {
      top: '-16px !important',
      backgroundColor: "#D9272E",
      borderRadius: '1px',
      display: 'flex',
      justifyContent: 'center',
      minWidth: '90px',
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "61px",
      '& .MuiPaper-root': {
      },
    }
  },
  link: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0),
    zIndex: "1800",
    [theme.breakpoints.down("sm")]: {
    },
    '&:hover': {
      color: "#D9272E"
    }
  },
  activeButton: {
    color: "#D9272E"
  },
  button: {
    color: '#FFFFFF',
    '&:hover': {
      color: '#D9272E',
      transition: '0.2s',
    }
  },
})

export const sliderHeader = (theme: Theme) => ({
  swiper: {
    position: "unset",
    display: "flex",
    alignItems: "center",
    overflow: 'visible',
    [theme.breakpoints.down("xs")]: {
    },
  },
  containerSlider: {
    [theme.breakpoints.down("sm")]: {
        paddingLeft: "unset",
        paddingRight: "unset",
    },
  },
  containerText: {
    textAlign: 'center',
    zIndex: 20,
    color: '#fff',
    left: '50%',
    bottom: '60px',
  },
  swiperSlide: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    aspectRatio: '22/9',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
      aspectRatio: '1/1',
      backgroundPosition: 'top',
    },
    [theme.breakpoints.up('sm')]: {
      left: '17%',
      right: '17%',
      backgroundPosition: 'center',
    },
    [theme.breakpoints.only('sm')]: {
    },
  },
  dynamicInfoDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  slideLogo: {
    filter: 'drop-shadow(5px 8px 8px #000)',
    margin: '0px auto',
    [theme.breakpoints.down('xs')]: {
      width: '60%',
      margin: '0px auto',
    },
    [theme.breakpoints.up('sm')]: {
      width: '30%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '50%',
    },
  },
  slideMoreComponent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    bottom: '0',
    background: 'linear-gradient(0deg, #090909, #090909bf, transparent)',
    [theme.breakpoints.down('sm')]: {
      background: 'linear-gradient(0deg, #090909, #090909, transparent)',
    }
  },
  info: {
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
  },
  date: {
    fontWeight: 500,
    textTransform: 'uppercase',
    textShadow: '4px 4px 6px black',
    backgroundColor: '#d10000',
    paddingInlineStart: '16px',
    paddingInlineEnd: '16px',
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 600,
      fontSize: '1em',
      letterSpacing: '1.2px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.2em',
      letterSpacing: '1.9px',
    },
  },
  description: {
    fontStyle: 'italic',
    color: 'black',
    backgroundColor: '#ffffffd1',
    width: 'fit-content',
    paddingInlineStart: '16px',
    paddingInlineEnd: '16px',
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1em',
    },
    '& * ': {
      marginTop: '0px',
    },
  },
})

export const sliderAbout = (theme: Theme) => ({
  swiper: {
    position: "unset",
    display: "flex",
    alignItems: "center",
  },
  containerSlider: {
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: "unset",
        paddingRight: "unset",
    }
  },
  swiperSlide: {
    flexDirection: "column"
  },
  textDiv: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: '1em',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: '3em',
    },
  },
  title: {
    fontWeight: 500,
    letterSpacing: '0.1em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6em',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.9em',
    },
  },
  description: {
    maxWidth: '600px',
  },
})

export const counterComponent = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  counter: {
    color: "#fff",
    margin: "20px 0px",
    textTransform: "uppercase",
  },
  times: {
    display: "flex",
    flexDirection: "row",
    fontSize: "2.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    }
  },
  time: {
    width: "25%",
    minWidth: "80px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "0px",
    },
  },
  infos: {
    display: "flex",
    flexDirection: "row"
  },
  info: {
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7em",
    }
  },
})