import React, { useContext } from "react";
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { PlaylistCard } from "../components/PlaylistCard";
import { Container, Typography, useMediaQuery } from "@material-ui/core";
import { CleverFormattedMessage } from "videokube-front-library/dist/Texts/CleverFormattedMessage";
import {Divider} from 'videokube-front-library/dist/Divider/Divider';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import { PlaylistContext } from "../playlistContext";
import { useFile } from "videokube-front-library";
import { DividerLeftStyle, PlaylistCardStyle } from "../theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        categoryImage: {
            height: '50vh',
            // paddingTop: '65px',
            minHeight: '250px',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            marginBottom: '-5em',
            [theme.breakpoints.only('sm')]: {
                maxHeight: '480px',
                marginBottom: '-10em',
            },
            [theme.breakpoints.down('xs')]: {
                height: '20vh',
                minHeight: 'auto',
                marginBottom: '0px',
            },
        },
        categHeaderDiv: {
            height: '57%',
            background: 'linear-gradient(rgba(0, 0, 0, 0), #090909c7, #090909)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        playlistIcon: {
            color: 'white',
            marginTop: '1em',
            [theme.breakpoints.up("md")]: {
                marginTop: '3em',
            },
        },
        playlistBody: {
            minHeight: '100vh',
            marginTop: '-15em',
            [theme.breakpoints.down("xs")]: {
                marginTop: '-4em',
            },
        },
        playlistGrid: {
            [theme.breakpoints.down("xs")]: {
                padding: '0px',
            },
        },
    }),
);


export const Playlist = () => {
    const classes = useStyles();
    const theme = useTheme();
    const {playlist} = useContext(PlaylistContext);
    const header = useFile("categories_header");
    let playlistEmpty = false;
    if (playlist.length === 0) {
        playlistEmpty = true;
        console.log('empty')
    }

    let sizeBackround = "?size=original"
    if (useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true })) {
        sizeBackround = "?size=medium"
    }

    return (
        <React.Fragment>
            <div className={classes.categoryImage} style={{ backgroundImage: `url(${header.file+sizeBackround})` }}>
                <div className={classes.categHeaderDiv} ></div>
            </div>
            <Container maxWidth="lg" className={classes.playlistBody}>
                <Typography component="h1" variant="h3" >
                    <CleverFormattedMessage id="playlist_title" />
                </Typography>
                <Divider style={DividerLeftStyle(theme)}/>
                <PlaylistPlayIcon fontSize="large" className={classes.playlistIcon} />
                {playlistEmpty &&
                    <Typography component="h3" variant="body1" >
                        <CleverFormattedMessage id="playlist_empty" />
                    </Typography>
                }
                {!playlistEmpty &&
                <>
                    {playlist.map((media) => (
                        <PlaylistCard
                            mediaLight={media}
                            designCard={PlaylistCardStyle(theme)}
                        />
                    ))}
                </>
                }
            </Container>
        </React.Fragment>
    )
}