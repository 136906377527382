import React from "react";
import { makeStyles, Theme, createStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ShowMoreText from "react-show-more-text";
import { useIntl } from "react-intl";
import { DurationFormat } from "videokube-front-library/dist/utils";
import {Divider} from 'videokube-front-library/dist/Divider/Divider';
import { Metadata } from "videokube-front-library/dist/types";
import { Chip } from "@material-ui/core";
import { DividerLeftStyle } from "../theme";

export interface VideoInformationProps {
  title?: string;
  duration?: number;
  productionYear?: number;
  language?: string;
  img: string;
  description?: string;
  keywords?: Keyword[];
  startAt?: Date;
  showMore?: boolean;
  style?: any;
  titlePlaying? : string;
  providerName?: Nullable<string>;
  providerNameSlug?: Nullable<string>;
  metadatas?: Metadata[];
  rating?: Nullable<number>;
}

export function VideoInfo(props: VideoInformationProps) {
  let styleNew = props.style || {}
  let classes = useStyles(styleNew)();
  const theme = useTheme();

  interface VideoKeywordsProps {
    keywords?: Keyword[];
    classes: Record<string, string>;
    size: "small" | "medium";
  }

  const VideoKeywords = (props: VideoKeywordsProps) => {
    let classes = props.classes;
    
    return (
      <React.Fragment>
        {props.keywords && props.keywords.length > 0 && (
          <Grid direction="row" className={classes.keywords}>
            {props.keywords.map(aKeyword => (
              //<Link to={`/keyword/${aKeyword.name}`} key={aKeyword.name}>
                <Chip size={props.size} className={classes.keywordChip}
                //onClick={() => true} className={classes.topSpacer}
                label={"#" + aKeyword.name} />
                //{" "}
              //</Link>
            ))}
          </Grid>
        )}
      </React.Fragment>
    )
  }

  return (
    <Grid container className={classes.root}>
      {/* Looks not appropriate. Should probably not be here. */}
      <Grid container item md={9} direction="column" className={classes.columnTwo}>
        {props.title && (
          <Typography variant="h6" component="h6">
            {props.title}
          </Typography>
        )}
        <Divider style={DividerLeftStyle(theme)}/>
        <VideoDuration duration={props.duration}/>
        <VideoMedia productionYear={props.productionYear} language={props.language}/>
        <VideoRating rating={props.rating} classes={classes}/>
        <VideoDescription description={props.description} showMore={props.showMore} classes={classes}/>
        <VideoKeywords keywords={props.keywords} classes={classes} size="medium"/>
      </Grid>
    </Grid>
  );
}

interface VideoDescriptionProps {
  description?: string;
  showMore?: boolean;
  classes: Record<string, string>;
};

const VideoDescription = (props: VideoDescriptionProps) => {
  let classes = props.classes;

  return (
    <React.Fragment>
    {props.description && (
      <React.Fragment>
      <Typography
        component={"span"}
        variant="body1"
        className={classes.description}
      >
        {props.showMore && (
          <ShowMoreText
            lines={1}
            width={1300}
            more="Read description"
            less="Less"
            anchorClass="MuiTypography-subtitle1"
          >
            <span>{props.description}</span>
          </ShowMoreText>
        )}
        {!props.showMore && <div dangerouslySetInnerHTML={{ __html: props.description }} />}
      </Typography>

    </React.Fragment>
    )}
    </React.Fragment>
  );
};

interface VideoMetaProps {
  productionYear?: number;
  language?: string;
}

const VideoMedia = (props: VideoMetaProps) => {
  return (
    <Typography variant="body1" style={{fontStyle: 'italic', fontSize: '0.8em', paddingTop: '0px'}}>
      {props.productionYear && (
        props.productionYear
          .toString()
          + ". "
          )}
          {props.language && (
            props.language
          )}
    </Typography>
  )
};




interface VideoDurationProps {
  duration?: number;
}

const VideoDuration = (props: VideoDurationProps) => {
  const { formatMessage } = useIntl();
  return (
    <React.Fragment>
      {props.duration && (
        <Typography variant="subtitle1" component="p" gutterBottom>
          {DurationFormat(props.duration, formatMessage)}
        </Typography>
      )}
    </React.Fragment>
  )
}

interface VideoRatingProps {
  rating?: Nullable<number>;
  classes: Record<string, string>;
}

const VideoRating = (props: VideoRatingProps) => {
  let classes = props.classes;
  const { formatMessage } = useIntl();
  return (
    <React.Fragment>
      {props.rating && (
        <Grid container className={classes.topSpacer}>
          <Grid item className={classes.divImdbRate}>
            <span className={classes.imdbRate} >
              {formatMessage({ id: "imdb_rate" })}
            </span>
            <span className={classes.imdbRate}>
              {props.rating}
            </span>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

interface VideoListMetasProps {
  metas?: string[];
  classes: Record<string, string>;
  prefix: string;
}

const useStyles = (extendsStyle:any) => makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "auto",
      boxShadow: "none"
    },
    topSpacer: {
      marginTop: "5px"
    },
    keywords: {
      margin: "10px 0px",
    },
    keywordChip: {
      backgroundColor: 'transparent',
      color: '#438FC9',
    },
    imdbRate: {
      paddingLeft: '5px',
    },
    divImdbRate: {
      paddingTop: '5px',
      fontSize: '13px',
      borderRadius: '5px',
      padding: '3px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.dark,
    },
    description: {
    },
    time: {
      fontWeight: "bold"
    },
    columnTwo: {
      paddingInlineStart: "0px",
      [theme.breakpoints.down("sm")]: {
        paddingInlineStart: "0px",
      }
    },
    ...extendsStyle,
  })
);
