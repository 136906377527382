import React, { useContext, useEffect } from "react";
import AddIcon from '@material-ui/icons/Add';
import { Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import { PlaylistContext } from "../playlistContext";
import { Media } from "videokube-front-library/dist/types";

export interface MediaLight {
    description: string;
    slug: string;
    preview: string; // image
    title: string;
    duration: number;
}

export interface AddToPlaylistProps {
    style?: any;
    media: Media;
}

const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
    createStyles({
        addToPlaylist: {
            color: 'white',
            padding: '0px',
            '&.MuiButton-root': {
                minWidth: 'auto',
            }
        },
        ...extendsStyle
    }),
);

export const AddToPlaylistButton = (props: AddToPlaylistProps) => {
    const classes = useStyles(props.style || {})();
    const [inPlaylist, setInPlaylist] = React.useState(false);
    const {playlist, toggleMediaInPlaylist} = useContext(PlaylistContext);
    const media = props.media;
    const onClick = () => {
        toggleMediaInPlaylist(mediaLight);
        inPlaylist?setInPlaylist(false):setInPlaylist(true);
    }

    let mediaLight : MediaLight =  {slug:media.slug,description:media.description, title:media.title,preview:media.preview, duration:media.duration};

    useEffect(() => {
        const getSlugFromPlaylist = async () => {
            let video = playlist.filter(data => data.slug === media.slug)
            if (video.length > 0) {
                setInPlaylist(true);
            } else {
                setInPlaylist(false);
            };
        };
        getSlugFromPlaylist();
    }, [inPlaylist, playlist, media]);

    return (
        <Button className={classes.addToPlaylist} onClick={onClick} >
            {!inPlaylist && (
                <AddIcon />
            )}
            {inPlaylist && (
                <CheckIcon />
            )}
        </Button>
    )
}