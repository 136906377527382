import React from 'react';
import { Box } from '@material-ui/core';

interface LoaderProps {
  height?: string;
  size?: string;
  margin?: string;
  backgroundColor?: string;
}

export function Loader(props:LoaderProps) {

  let height = props.height || '100vh';
  let size = props.size || '100px'
  let margin = props.margin || '0';
  let backgroundColor = props.backgroundColor || '#090909';
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={1}
      m={1}
      css={{ height: height, padding: '0', margin: margin }}
      style={{backgroundColor: backgroundColor}}
    >
      <div className="loaderContainer" style={{height : size, width : size}}>
      <div className="loaderSpec loaderred"></div>
      </div>
    </Box>
  );
}
