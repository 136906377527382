import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { Category, Media } from 'videokube-front-library/dist/types';
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import flatMap from "lodash/flatMap";
import { Button, Container, createStyles, Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { SingleVideoCard } from "./SingleVideoCard";
import { DividerLeftStyle, singleVideoCardInCategory } from '../theme';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CleverFormattedMessage } from "videokube-front-library";
import {Divider} from 'videokube-front-library/dist/Divider/Divider';

const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
    createStyles({
        keywordBtnGeneral: {
            textTransform: 'capitalize',
            fontWeight: 'normal',
            color: 'white',
            border: '2px solid #10477e',
            margin: '6px',
        },
        keywordButton: {
        },
        keywordButtonHighlight: {
            backgroundColor: '#10477e !important',
        },
        showMoreBtn: {
            margin: '20px auto 0px',
            display: 'flex',
            color: 'white',
            textTransform: 'capitalize',
            fontWeight: 'normal',
        },
        keywordDiv: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingBottom: '2em',
            [theme.breakpoints.up('sm')]: {
                paddingTop: '1em',
            },
        },
        ...extendsStyle,
    }),
);

interface groupKeywordCount {
    keyword: string,
    nb: number,
    medias: Media[]
}

interface KeywordsCloudProps {
    style?: any
}

export function KeywordsCloud(props: KeywordsCloudProps) {
    let styleNew = props.style || {}
    let classes = useStyles(styleNew)();
    const theme = useTheme();
    let categories = useSelector<AppState>(
        (state) => state.app.categories,
    ) as Category[];


    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true });

    // Nombre de videos qu'on souhaite avoir en XS et MD
    let nbItemsMd = 3;
    let nbItemsXs = 2;

    let nbItems = nbItemsMd * 2;
    let spacing: 0 | 3 | 2 | 1 | 10 | 7 | 6 | 5 | 4 | 8 | 9 | undefined
    spacing = 3;

    if (isXs) {
        nbItems = nbItemsXs * 2;
        spacing = 2;
    }

    const mdColumns: any = Math.floor(12 / nbItemsMd);
    const xsColumns: any = Math.floor(12 / nbItemsXs);

    const [nbVideo, setNbVideo] = useState<number>(nbItems)

    let allMedias = categories.flatMap((aCategory) => aCategory.medias)
    let allKeywordWithMedia = flatMap(allMedias, media => media.keywords.map((k) => { return { keykword: k.name, medias: media } }))

    let grpAllKeywordWithMedia = groupBy(allKeywordWithMedia, x => x.keykword)
    let sortedGrpB = Object.entries(grpAllKeywordWithMedia).map(entrie => { return { keyword: entrie[0], nb: entrie[1].length, medias: entrie[1].map((x) => x.medias) } })

    let keywordSorted: groupKeywordCount[] = orderBy(sortedGrpB, x => x.nb, "desc").slice(0, 10)

    const [selectedKeyword, setSelectedKeyword] = useState<groupKeywordCount | null>(keywordSorted[0] ? keywordSorted[0] : null);
    let mediaOrdered = orderBy(selectedKeyword?.medias, x => x.id, "desc") || []
    let mediaTodisplay = selectedKeyword ? mediaOrdered.slice(0, nbVideo) : orderBy(keywordSorted[0].medias, x => x.id, "desc").slice(0, nbVideo)

    const showMoreFunction = () => {
        setNbVideo(nbVideo + nbItems)
    }

    const displayVideoFromKeyword = (keyword: groupKeywordCount) => {
        if (keyword === selectedKeyword && nbVideo !== 0) {
            setNbVideo(0)
        } else {
            setNbVideo(nbItems)
        }
        setSelectedKeyword(keyword);
    }

    return (
        <Container maxWidth="lg" >
            <div className={classes.keywordDiv}>
                {keywordSorted && keywordSorted.map((keyword) => (
                    <Button
                        className={classes.keywordBtnGeneral + " " + `${selectedKeyword?.keyword === keyword.keyword ? classes.keywordButtonHighlight : classes.keywordButton}`}
                        onClick={() => { displayVideoFromKeyword(keyword) }}>
                        {keyword.keyword}
                    </Button>
                ))}
            </div>
            <Grid container key={"videoContainer"} justify="center" spacing={spacing}>
                {mediaTodisplay.length > 0 && mediaTodisplay.map((media) => (
                    <Grid item key={media.id} md={mdColumns} xs={xsColumns}>
                        <SingleVideoCard height={250} 
                            media={media}
                            backgroundMode
                            style={singleVideoCardInCategory(theme)} />
                    </Grid>
                ))}
            </Grid>
            {selectedKeyword && mediaTodisplay.length < selectedKeyword.medias.length && (
                <Button
                    variant="text" color="primary"
                    className={classes.showMoreBtn}
                    onClick={() => showMoreFunction()} >
                    <KeyboardArrowDownIcon />
                    <CleverFormattedMessage id="show_more_button" />
                </Button>
            )}
        </Container>
    )
}