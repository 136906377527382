import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme, createStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Link } from "react-router-dom";
import { CleverFormattedMessage } from "videokube-front-library/dist/Texts/CleverFormattedMessage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: FooterStyle) => ({
      color: props.color,
      maxWidth: props.maxWidth,
      margin: '0 auto',
      backgroundColor: '#090909',
      //flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      },
      [theme.breakpoints.up("md")]: {
        marginTop: '3em',
      },
      border: props.borderStyle,
      borderWidth: props.borderWidth
    }),
    svg: (props: FooterStyle) => ({
      color: props.color,
      verticalAlign: "middle",
      fontSize: "2.5rem",
      padding: "0px 10px 0px 10px",
      fill: "white"
    }),
    footerText: (props: FooterStyle) => ({
      color: props.color,
      padding: "25px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      },
      '& a': {
        textDecoration: 'none'
      }
    }),
    footerIcon: (props: FooterStyle) => ({
      color: props.color,
      padding: "25px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center"
      }
    }),
    footerButton: (props: FooterStyle) => ({
      color: props.color,
      textAlign: 'start',
      marginRight: "35px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px"
      }
    })
  })
);

interface FooterProps {
  otherColor?: OtherColor;
  borderStyle?: BorderStyle;
  maxWidth?: string;
  links: any;
  instagram?: string;
  facebook?: string;
  twitter?: string;
  linkedin?: string;
  youtube?: string;
}

interface OtherColor {
  color: string;
  backgroundColor: string;
}

interface BorderStyle {
  style: string;
  width: string;
}

interface FooterStyle {
  color: string;
  backgroundColor: string;
  borderStyle: string;
  borderWidth: string;
  maxWidth?: string;
}

export function Footer(props: FooterProps) {
  // Possibilité de changer de couleurs
  const theme = useTheme<Theme>();
  const otherStyles:FooterStyle = {color: theme.palette.primary.main, backgroundColor: theme.palette.secondary.main, borderStyle: "none", borderWidth: "0", maxWidth: 'unset'};
  if(props.otherColor) {
    if(props.otherColor.color) {
      otherStyles.color = props.otherColor.color;
    }
    if(props.otherColor.backgroundColor) {
      otherStyles.backgroundColor = props.otherColor.backgroundColor;
    }
  }
  if(props.borderStyle) {
    if(props.borderStyle.style) {
      otherStyles.borderStyle = props.borderStyle.style;
    }
    if(props.borderStyle.width) {
      otherStyles.borderWidth = props.borderStyle.width;
    }
  }
  if(props.maxWidth) {
      otherStyles.maxWidth = props.maxWidth;
  }

  const classes = useStyles(otherStyles);

  return (
    <Box display="flex" className={classes.root}>
      {/* {props.instagram || props.facebook || props.twitter && ( */}
      <Box className={classes.footerIcon} display="flex" flexGrow={1}>
        {props.instagram && (
          <a href={props.instagram} rel="noopener noreferrer" target="_blank">
            <InstagramIcon className={classes.svg} />
          </a>
        )}
        {props.facebook && (
          <a href={props.facebook} rel="noopener noreferrer" target="_blank">
            <FacebookIcon className={classes.svg} />
          </a>
        )}
        {props.twitter && (
          <a href={props.twitter} rel="noopener noreferrer" target="_blank">
            <TwitterIcon className={classes.svg} />
          </a>
        )}
        {props.linkedin && (
          <a href={props.linkedin} rel="noopener noreferrer" target="_blank">
            <LinkedInIcon className={classes.svg} />
          </a>
        )}
        {props.youtube && (
          <a href={props.youtube} rel="noopener noreferrer" target="_blank">
            <YouTubeIcon className={classes.svg} />
          </a>
        )}
      </Box>
      {/* )} */}
      {/* Fix rapide car sinon le design est pété. J'arrive pas a mettre les links à droite
      Pour esoudre le problème j'ai rajouté une box vide.*/}
      {!props.instagram && !props.facebook && !props.twitter && !props.youtube &&(
        <Box  display="flex" flexGrow={1}/>
      )}
      <Box className={classes.footerText} display="flex" flexWrap="wrap" justifyContent="end">
          {props.links.map((link: any) => (
            <div key={link.title}>
              {!link.slug.startsWith('/') &&
                <a href={link.slug} key={link.slug} rel="noopener noreferrer" target="_blank">
                  <Button className={classes.footerButton}>
                    <CleverFormattedMessage id={link.title}/>
                  </Button>
                </a>
              }
              {link.slug.startsWith('/') &&
                <Link to={link.slug} key={link.slug}>
                  <Button className={classes.footerButton}>
                    <CleverFormattedMessage id={link.title}/>
                  </Button>
                </Link>
              }
            </div>
          ))}
      </Box>
    </Box>
  );
}

