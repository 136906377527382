import React from 'react';
import { CleverFormattedMessage, useFile } from 'videokube-front-library';
import {Divider} from 'videokube-front-library/dist/Divider/Divider';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import { SliderAbout } from '../components/SliderAbout';
import { DividerCenterStyle, DividerLeftStyle, sliderAbout } from '../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgContainer: {
      display: 'flex',
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        margin: '5em auto',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '-5em',
      },
    },
    imgDiv: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      width: '100%',
      height: 'auto',
      margin: '10px 0px',
      color: 'white',
      [theme.breakpoints.only('sm')]: {
        width: 'auto !important',
      },
      [theme.breakpoints.up('sm')]: {
        width: '49%',
        margin: '0px 10px',
      },
    },
    halfImage: {
      width: '100%',
      height: 'auto',
      [theme.breakpoints.only('sm')]: {
        maxWidth: '420px',
        margin: '0px auto',
      },
    },
    centerDiv: {
      height: '50vh',
      color: 'white',
      textAlign: 'center',
      backgroundSize: 'cover !important',
      minHeight: '300px',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.only('sm')]: {
        height: '350px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '30vh',
        minHeight: '290px',
        textAlign: 'initial',
      },
    },
    spacingDiv: {
      height: '30%',
      background: 'linear-gradient(transparent, #090909)',
    },
    containerCenter: {
      padding: '0px 16px',
    },
    centerDescription: {
      maxWidth: '720px',
      margin: '0px auto',
    },
    gifContainer: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    media: {

    },
    gifDiv: {
      width: '100%',
      height: 'auto',
      margin: '10px 0px',
      [theme.breakpoints.up('sm')]: {
        width: '49%',
        margin: '0px 10px',
      },
    },
    }),
);

export function About() {
  const classes = useStyles();
  const theme = useTheme();
  const header = useFile("categories_header");
  const picture = useFile("about_us_mockup");
  const video = useFile("about_video");
  const videoMp4 = useFile("about_video_mp4");

  return (
    <div>
      <div className={classes.centerDiv}
        style={{backgroundImage: `url(${header.file+"?size=original"})`, justifyContent: 'flex-end', padding: '0px 16px' }}>
        <Typography component="h1" variant="h1" >
          <CleverFormattedMessage id="about_us_title" />
          <Divider style={DividerCenterStyle(theme)}/>
        </Typography>
          <CleverFormattedMessage id="about_us_subtitle" />
        <div className={classes.spacingDiv}></div>
      </div>
      <Container className={classes.imgContainer} maxWidth='lg'>
        <div className={classes.imgDiv}>
          <div>
            <Typography component="h2" variant="h4"  >
              <CleverFormattedMessage id="title_section_two" />
              <Divider style={DividerLeftStyle(theme)}/>
            </Typography>
            <CleverFormattedMessage id="about_interstice_description" />
          </div>
          <div>
            <Typography component="h2" variant="h4"  >
              <CleverFormattedMessage id="title_section_two_videos" />
              <Divider style={DividerLeftStyle(theme)}/>
            </Typography>
            <CleverFormattedMessage id="text_section_two_videos" />
          </div>
        </div>
        <div className={classes.imgDiv}>
          <img className={classes.halfImage}
            src={picture.file+"?size=original"}
            alt="mobile mockups of our live fight stream service" />
        </div>
      </Container>
      <div className={classes.centerDiv}
        style={{background: `linear-gradient(#090909, transparent, transparent, #090909),url(${header.file+"?size=original"})`,}} >
        <div className={classes.containerCenter}>
          <Typography component="h3" variant="h4"  >
            <CleverFormattedMessage id="about_disciplines_title" />
          </Typography>
          <CleverFormattedMessage className={classes.centerDescription} id="about_half_description" />
        </div>
      </div>
      <Container className={classes.gifContainer} maxWidth='lg'>
        <div className={classes.gifDiv}>
          <video autoPlay={true} loop={true} preload='auto' muted width="100%" playsInline>
              <source src={video.file} type="video/webm" />
              <source src={videoMp4.file} type="video/mp4"/>
          </video>
        </div>
        <div className={classes.gifDiv}>
          <SliderAbout style={sliderAbout(theme)}/>
        </div>
      </Container>
    </div>
  );
}
