import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { CleverFormattedMessage, useFile } from "videokube-front-library";
import { Container, useMediaQuery } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { Divider } from 'videokube-front-library/dist/Divider/Divider';
import { SingleVideoCard } from "./SingleVideoCard";
import { Media as IMedia } from 'videokube-front-library/dist/types';
import { getMedias } from "videokube-front-library/dist/api";
import { DividerLeftStyle, singleVideoCardInVideoForYou } from "../theme";

export interface VideoForYouProps {
  style?: any;
}

const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
  createStyles({
    randomVideoContainer: {
      marginTop: '16vh',
      [theme.breakpoints.down('sm')]: {
        marginTop: '14vh',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '11vh',
      },
    },
    randomVideo: {
      marginTop: '3em',
      marginBottom: '1vh',
      [theme.breakpoints.only('sm')]: {
        marginTop: '5vh',
        marginBottom: '0vh',
        maxWidth: '90%',
        margin: '0px auto',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '1em',
        marginBottom: '0vh',
      },
    },
    randomVideoBackground: {
      maxWidth: '1240px',
      margin: '0px auto',
      backgroundPosition: 'bottom',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '47em',
      marginBottom: '12vh',
      [theme.breakpoints.only('sm')]: {
        height: '630px',
        marginBottom: '12vh',
      },
      [theme.breakpoints.between('sm', 'xs')]: {
        height: '630px',
        marginBottom: '12vh',
      },
      [theme.breakpoints.down('xs')]: {
        height: '310px',
        marginBottom: '7vh',
      },
    },
    ...extendsStyle
  }),
);

export function VideoForYouDiv(props: VideoForYouProps) {
  const theme = useTheme();
  const classes = useStyles(props.style || {})();
  const [latestMedia, setLatestMedia] = useState<IMedia>()
  const nbMaxVideo = 20

  useEffect(() => {
    const latestMedias = async () => {
      let linkedMedias = await getMedias({ sense: "desc", limit: nbMaxVideo, live: 0 });
      let nbRand = Math.floor(Math.random() * nbMaxVideo);
      setLatestMedia(linkedMedias[nbRand]);
    };
    latestMedias();
  }, []);

  const picurePaper = useFile("paper_img");
  let sizeBackround = "?size=original"
  if (useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true })){
    sizeBackround = "?size=medium"
  }
  let picure = picurePaper.file + sizeBackround;

  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.randomVideoContainer} >
        <Typography component="h3" variant="h3" >
          <CleverFormattedMessage id="video_for_you_title" />
        </Typography>
        <Divider style={DividerLeftStyle(theme)}/>
      </Container>
      <div className={classes.randomVideoBackground} style={{ backgroundImage: `url(${picure})` }}>
        {latestMedia && (
          <Container maxWidth="md" className={classes.randomVideo} >
            <SingleVideoCard height={510}
              media={latestMedia}
              backgroundMode
              style={singleVideoCardInVideoForYou(theme)}
            />
          </Container>
        )}
      </div>
    </React.Fragment>
  );
}