import React, { createContext, useState } from 'react';
import { MediaLight } from './components/AddToPlaylist';
import { CustomSnackBar } from './components/CustomSnackBar';
import { useText } from 'videokube-front-library/dist/hooks';
import { useTheme } from '@material-ui/core';
import { CustomSnackBarStyle } from './theme';

const playlistContextLocalStorageKey = 'videokubePlaylist';

const readPlaylistFromLocalStorage = (): MediaLight[] => {
  try {
    return JSON.parse(
      localStorage.getItem(playlistContextLocalStorageKey) || '[]',
    );
  } catch (e) {
    //In case we couldn't parse properly (...)
    return [];
  }
};

export const PlaylistContext = createContext({
  playlist: [] as MediaLight[],
  toggleMediaInPlaylist: (media: MediaLight) => {},
});

export const PlayListContextHandler = ({children}: {children: any}) => {
  const theme = useTheme();
  const [playlist, setPlaylist] = useState(readPlaylistFromLocalStorage());
  const addToPlaylist = useText("add_to_playlist").value;
  const removeFromPlaylist = useText("remove_from_playlist").value;
  const [state, setState] = React.useState<{open: boolean, text: string}>({
    open: false,
    text: "",
  });
  let newState = {text: ""};


  return (
    <PlaylistContext.Provider
      value={{
        playlist: playlist,
        toggleMediaInPlaylist: (media: MediaLight) => {

          const foundMedia = playlist.find((m) => m.slug === media.slug);
          let newPlaylist = playlist;
          if (foundMedia) {
            newState = {text: removeFromPlaylist};
            newPlaylist = playlist.filter((m) => m.slug !== media.slug);
          } else {
            newState = {text: addToPlaylist};
            newPlaylist = [...playlist, media];
          }
          setPlaylist(newPlaylist);
          setState({ open: true, ...newState });
          localStorage.setItem(
            playlistContextLocalStorageKey,
            JSON.stringify(newPlaylist),
          );
        },
      }}
    >
      <CustomSnackBar key={new Date().getTime()} state={state} setState={setState} style={CustomSnackBarStyle(theme)}/>
      {children}
    </PlaylistContext.Provider>
  );
};
