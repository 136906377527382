import React from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  HeaderImage,
  CleverFormattedMessage,
  ListCategories
} from 'videokube-front-library';
import { Container } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { AppState } from '../../store';
import { useSelector } from 'react-redux';
import { Category, IFile } from 'videokube-front-library/dist/types';
import { useIntl } from 'react-intl';
import { responsiveHeaderImage } from '../../theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    description: {
      lineHeight: '24px',
      marginTop: '40px',
    },
  }),
);

export function Topics() {
  const classes = useStyles();
  const theme = useTheme();
  const files = useSelector<AppState, IFile[]>((state) => state.app.files);
  const categories = useSelector<AppState>(
    (state) => state.app.categories,
  ) as Category[];
  const { formatMessage } = useIntl();
  const replayCenterTitle = formatMessage({id: 'replay_center_title'});
  let topics_header = files.find(
    (file) => file.name === 'topics_header',
  );

  if (
    !topics_header
  ) {
    return <div>OK</div>;
  }

  return (
    <div className={classes.root}>
      <HeaderImage style={responsiveHeaderImage(theme)} title={replayCenterTitle} img={topics_header.file +  "?size=original"} />

      <Container>
        <Hidden smDown>
          <Typography
            component="p"
            variant="body2"
            className={classes.description}
          >
            <CleverFormattedMessage id="categories_description" />
          </Typography>
        </Hidden>
      </Container>
      
      <ListCategories categories={categories} />
    </div>
  );
}
