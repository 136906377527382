import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Button, Container, useMediaQuery, useTheme, Theme, makeStyles, createStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {
  CleverFormattedMessage,
} from 'videokube-front-library';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { Category } from 'videokube-front-library/dist/types';
import { Link, useHistory, useParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { SingleVideoCard } from './SingleVideoCard';
import { DividerLeftStyle, singleVideoCardInCategory } from '../theme';
import CloseIcon from '@material-ui/icons/Close';
import {Divider} from 'videokube-front-library/dist/Divider/Divider';

const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
  createStyles({
    videoCategoryDiv: {
      width: "100%",
      background: 'linear-gradient( #001025, #438FC9, #001025)',
      padding: '2em 6px',
      margin: '6em 0px',
      [theme.breakpoints.down('sm')]: {
        margin: '3em 0px',
      },
    },
    categoryGrid: {
      [theme.breakpoints.up('xs')]: {
        marginTop: '2em',
      },
    },
    categName: {
      color: 'white',
      position: 'relative',
      zIndex: 2,
    },
    titleHolder: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    closeButton: {
      padding: '0px',
      color: 'white',
    },
    background: {
      height: '18vh',
      minHeight: '220px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      width: '100%',
      backgroundSize: 'cover !important',
      backgroundPosition: 'center !important',
      transition: 'all 0.2s',
      backfaceVisibility: 'hidden',
      [theme.breakpoints.down('md')]: {
        minHeight: '25vh',
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: '185px',
        boxShadow: '3px 3px 10px 3px rgb(0 0 0 / 52%)',
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '13vh',
        height: 'auto',
      },
      boxShadow: '0 4px 12px -1.5px rgba(0, 0, 0, 0.3)',
      '&:hover': {
        boxShadow: '4px 2px 20px 7px rgb(0 0 0 / 82%)',
      },
    },
    videoGrid: {
      marginTop: '2em',
      [theme.breakpoints.down('sm')]: {
        marginTop: '0em',
      },
    },
    buttonsShowMore: {
      display: 'flex',
      justifyContent: 'center',
    },
    showMoreBtn: {
      marginTop: '20px',
      color: 'white',
      fontWeight: 'normal',
      letterSpacing: '1.2px',
      fontSize: '0.8em',
    },
    backgroundHighlight: {
      height: '18vh',
      minHeight: '220px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      width: '100%',
      backgroundSize: 'cover !important',
      backgroundPosition: 'center !important',
      outline: "2px solid #438FC9",
      backfaceVisibility: 'hidden',
      [theme.breakpoints.up('md')]: {
        boxShadow: '0 4px 12px -1.5px rgba(0, 0, 0, 0.3)',
        '&:hover': {
          boxShadow: '4px 2px 20px 7px rgb(0 0 0 / 82%)',
        },
      },
      [theme.breakpoints.down('md')]: {
        height: '300px',
      },
      [theme.breakpoints.down('sm')]: {
        height: '300px',
        boxShadow: '3px 3px 10px 3px rgb(0 0 0 / 52%)',
      },
      [theme.breakpoints.down('xs')]: {
        minHeight: '13vh',
        height: '160px',
      },
    },
    ...extendsStyle
  }),
);

interface DisplayCategProps {
  onlyCateg?: boolean,
  style?: any,
}

export function DisplayCategWithVideo(props: DisplayCategProps) {
  let styleNew = props.style || {}
  let classes = useStyles(styleNew)();
  const theme = useTheme();
  let displayOnlyCateg = props.onlyCateg || false;
  let history = useHistory()
  let categoriesD = useSelector<AppState>(
    (state) => state.app.categories,
  ) as Category[];
  let categories = categoriesD.filter((categ) => categ.medias.length > 0)
  let defaultC: Category | null = null;
  let { slug } = useParams<{ slug: string }>();

  if (slug) {
    let tmpC = categories.filter((categ) => slug === categ.slug)[0]
    //console.log(slug)
    defaultC = tmpC ? tmpC : null
  }

  const [selectedCateg, setSelectedCateg] = useState<Category | null>(defaultC);

  useEffect(() => {
    const scroll = async () => {
      if(selectedCateg){
        document.getElementById(selectedCateg.slug)?.scrollIntoView({
          behavior: "smooth",
          block: "start"});

      }
    };
    scroll();
  }, [selectedCateg]);

  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true });

  // Nombre de videos qu'on souhaite avoir en XS et MD
  let nbItemsMd = 3;
  let nbItemsXs = 2;
  let nbItemsMdForVideo = 6;
  let nbItemsXsForVideo = 4;

  let nbItems = nbItemsMd;
  let nbItemsForVideo = nbItemsMdForVideo;

  if (isXs) {
    nbItems = nbItemsXs;
    nbItemsForVideo = nbItemsXsForVideo;
  }


  const mdColumns: any = Math.floor(12 / nbItemsMd);
  const xsColumns: any = Math.floor(12 / nbItemsXs);

  const [nbVideo, setNbVideo] = useState<number>(nbItems)

  // Tri par la derniere video inserer dans le back
  let mediaSorted = orderBy(selectedCateg?.medias, x => x.id, "desc");
  let videoToDisplay = mediaSorted.slice(0, nbVideo);

  const showMoreFunction = () => {
    setNbVideo(nbVideo + nbItemsForVideo)
  }
  const showAllFunction = () => {
    let allVideo = selectedCateg?.medias.length || nbVideo
    setNbVideo(allVideo)
  }
  const displayVideoFromCateg = (category: Category) => {
    if (displayOnlyCateg) {
      history.push("/category/" + category.slug);
    }
    if (category === selectedCateg && nbVideo !== 0) {
      setNbVideo(0);
      setSelectedCateg(null)
      return;
    } else {
      setNbVideo(nbItemsForVideo)
    }
    setSelectedCateg(category);
  }

  let index = categories.findIndex((c) => c === selectedCateg);
  // To keep for other render , inline category
  //let position = Math.floor(index / nbItems) * nbItems + (nbItems - 1)
  let position = Math.min(index, categories.length - 1)

  return (
    <Container maxWidth="lg" >
      <Link to={`/category/`}>
        <Typography component="h2" variant="h3" >
          <CleverFormattedMessage id="categories_title" />
        </Typography>
        <Divider style={DividerLeftStyle(theme)}/>
      </Link>
      <Grid container spacing={3} className={classes.categoryGrid} >
        {categories && categories.map((category, categoryIndex) => (
          <React.Fragment>
            {category.medias.length > 0 && (
              // Gestion affichage des categories
              <Grid item
              id={category.slug}
              key={category.id}
              md={selectedCateg?.id === category.id ? 12 : mdColumns}
              xs={selectedCateg?.id === category.id ? 12 : xsColumns}
              //xs={12}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={`${selectedCateg?.id === category.id ? classes.backgroundHighlight : classes.background}`}
                  onClick={() => { displayVideoFromCateg(category) }}
                  //Diffrent image compression mode for selectedCateg. To activate after some necessary dev
                  //style={{ background: 'url(' + category.visual + `${selectedCateg?.id === category.id ? "?size=original" : "?size=small"}` }}>
                  style={{ background: 'url(' + category.visual + "?size=original" }}>
                </Button>
                {selectedCateg?.id !== category.id ? 
                <Typography variant="h5" component="h3" className={classes.categName}>{category.name}</Typography> : ''
                }
              </Grid>
            )}
            {/* Gestion affichage des videos sous les lignes de categ */}
            {selectedCateg && videoToDisplay && videoToDisplay.length > 0 && categoryIndex === position && (
              <div className={classes.videoCategoryDiv} >
                <div className={classes.titleHolder}>
                  <Typography variant="h3">{selectedCateg.name}</Typography>
                  <Button
                    variant="text"
                    className={classes.closeButton}
                    onClick={() => { displayVideoFromCateg(selectedCateg) }}>
                    <CloseIcon />
                  </Button>
                </div>
                <Divider style={DividerLeftStyle(theme)}/>
                <Grid className={classes.videoGrid} container key={category.id} spacing={2} >
                  {videoToDisplay.map((media) => (
                    <Grid item key={category.name} md={mdColumns} xs={xsColumns} >
                      <SingleVideoCard height={230} 
                        media={media}
                        backgroundMode
                        style={singleVideoCardInCategory(theme)} />
                    </Grid>
                  ))}
                </Grid>
                {selectedCateg && videoToDisplay.length < selectedCateg.medias.length && (
                  <div className={classes.buttonsShowMore}>
                    <Button
                      variant="text" color="primary"
                      className={classes.showMoreBtn} onClick={() => showMoreFunction()}>
                      <KeyboardArrowDownIcon />
                      <CleverFormattedMessage id="show_more_button" />
                    </Button>
                    <Button
                      variant="text" color="primary"
                      className={classes.showMoreBtn}
                      onClick={() => showAllFunction()} >
                      <UnfoldMoreIcon />
                      <CleverFormattedMessage id="show_all_button" />
                    </Button>
                  </div>
                )}
                {selectedCateg && videoToDisplay.length >= selectedCateg.medias.length && (
                  <Button
                    variant="text" color="primary"
                    className={classes.showMoreBtn}
                    onClick={() => { displayVideoFromCateg(selectedCateg) }} >
                    <CloseIcon />
                    <CleverFormattedMessage id="close_all" />
                  </Button>
                )}
              </div>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Container>
  );
}