import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { CleverFormattedMessage } from 'videokube-front-library';
// import { IEvent } from 'videokube-front-library/dist/types';
import { sleep } from 'videokube-front-library/dist/utils';
import {Loader} from '../../components/Loader';
import Typography from '@material-ui/core/Typography';
  
// const liveEvent = events.find(
//     anEvent => moment(anEvent.startTime).isAfter(moment().subtract(5, 'hour')) &&
//     moment(anEvent.startTime).isBefore(moment())
//   );
//   const events = useSelector<AppState, IEvent[]>((state) => state.app.events);
//   let nextEvents = events.filter(d => new Date(d.startTime).getTime() - new Date().getTime() > 0 );

// let link = "/schedule";
// let textButtonLive = "button_schedule";
// if (liveEvent){
//   link = "/tv";
//   textButtonLive = "button_live"
// } 

// const ColorButton = withStyles((theme) => ({
//     root: {
//       color: 'white',
//       backgroundColor: '#04B404',
//       '&:hover': {
//         backgroundColor: '#0B610B',
//       },
//     },
//   }))(Button);



const useStyles = makeStyles((theme: Theme) =>
createStyles({
  TextLoader: {
  minHeight: '88vh',
   },
  Title: {
    marginTop: '20vh',
    textAlign: 'center',
    fontSize: '1em',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
    fontSize: '0.8em',
    },
  },
  Text: {
    padding: '50px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
    },
  },
}),
);



export function ThankYouPage() {  
  const classes = useStyles();
  const history = useHistory();

  const waiting= async () => {
    await sleep(3000);
    history.push("/");
  }
  waiting();

      
    return (
    <div 
    className={classes.TextLoader}
    >
      <Typography component="h1" variant="h1" color="primary" >
              <CleverFormattedMessage id="thank_you_title" className={classes.Title} />
      </Typography>
        <CleverFormattedMessage id="thank_you_text" className={classes.Text} />
          <Loader height='15vh' margin='30px'/>
        {/* <Link to={link}>
            {liveEvent && (
              <ColorButton variant="contained" color="primary" className={classes.button}>
                <CleverFormattedMessage id={textButtonLive} />
            </ColorButton>
            )}
            {!liveEvent && (
              <Button className={classes.button} variant="contained" color='primary'>
                <CleverFormattedMessage id={textButtonLive} />
              </Button>
            )}
            </Link>
        <Link to={"/replay-center"}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
              >
                <CleverFormattedMessage id="replay_center" />
              </Button>
        </Link> */}
    </div>
    );
    }

