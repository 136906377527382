import React from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { Button, createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { CleverFormattedMessage } from 'videokube-front-library';

export interface CustomSnackbarProps {
    style?: any;
    state:{open: boolean, text: string};
    setState: (open:any)=>void;
}
const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
    createStyles({
        alert:{
            backgroundColor: "#090909cc",
            color: '#FFFFFF',
        },
        alertLink: {
            color: '#FFFFFF',
        },
        ...extendsStyle
    }),
);

export const CustomSnackBar = (props: CustomSnackbarProps) => {
    const classes = useStyles(props.style || {})();
    let state = props.state;

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'timeout') {
            let newState = {text: state.text};
            props.setState({ ...newState , open: false});
            return;
          }
        let newState = {text: state.text};
        props.setState({ ...newState , open: true});
    };

    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="outlined" className={classes.alert} {...props} action={
                <Button
                    className={classes.alertLink}
                    color="secondary"
                    size="small"
                    href="/playlist"
                    >
                    <CleverFormattedMessage id="playlist_link" />
                </Button>
          } />;
    }
        
    return (
        <Snackbar
            open={state.open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
            <Alert severity="success" color='error'>{state.text}</Alert>
        </Snackbar>
    )
  }