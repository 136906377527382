import React from 'react';
import { Typography, makeStyles, Theme, createStyles, Container, useMediaQuery } from '@material-ui/core';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import 'swiper/swiper.scss';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { IEvent } from 'videokube-front-library/dist/types';
import { getCurrentLang } from 'videokube-front-library/dist/utils';
import { CleverFormattedMessage } from 'videokube-front-library/dist/Texts/CleverFormattedMessage';

const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
  createStyles({
    swiper: {
        position: "unset",
        display: "flex",
        alignItems: "center",
        overflow: 'visible',
      [theme.breakpoints.down("xs")]: {
      },
    },
    containerSlider: {
      paddingTop: '5px',
      [theme.breakpoints.down("sm")]: {
          paddingLeft: "unset",
          paddingRight: "unset",
      },
    },
    containerText: {
      textAlign: 'center',
      zIndex: 20,
      color: '#fff',
      left: '50%',
      bottom: '60px',
    },
    swiperSlide: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      aspectRatio: '22/9',
      [theme.breakpoints.down('xs')]: {
        width: '100% !important',
        aspectRatio: '1/1',
        backgroundPosition: 'top',
      },
      [theme.breakpoints.up('sm')]: {
        left: '17%',
        right: '17%',
        backgroundPosition: 'center',
      },
      [theme.breakpoints.only('sm')]: {
      },
    },
    dynamicInfoDiv: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    slideLogo: {
      filter: 'drop-shadow(5px 8px 8px #000)',
      margin: '0px auto',
      [theme.breakpoints.down('xs')]: {
        width: '60%',
        margin: '0px auto',
      },
      [theme.breakpoints.up('sm')]: {
        width: '30%',
      },
      [theme.breakpoints.only('sm')]: {
        width: '50%',
      },
    },
    slideMoreComponent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      bottom: '0',
      background: 'linear-gradient(0deg, #090909, #090909bf, transparent)',
      [theme.breakpoints.down('sm')]: {
        background: 'linear-gradient(0deg, #090909, #090909, transparent)',
      }
    },
    info: {
      [theme.breakpoints.down('xs')]: {
        padding: '0px',
      },
    },
    date: {
      fontWeight: 500,
      textTransform: 'uppercase',
      textShadow: '4px 4px 6px black',
      backgroundColor: '#d10000',
      paddingInlineStart: '16px',
      paddingInlineEnd: '16px',
      width: 'fit-content',
      [theme.breakpoints.down('sm')]: {
        fontWeight: 600,
        fontSize: '1em',
        letterSpacing: '1.2px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.2em',
        letterSpacing: '1.9px',
      },
    },
    description: {
      fontStyle: 'italic',
      color: 'black',
      backgroundColor: '#ffffffd1',
      width: 'fit-content',
      paddingInlineStart: '16px',
      paddingInlineEnd: '16px',
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.4em',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1em',
      },
      '& * ': {
        marginTop: '0px',
      },
    },
    ...extendsStyle
  }));

interface Slider {
  style?: any;
}
SwiperCore.use([Autoplay]);


export const SliderHeader = (props: Slider) => {
  const classes = useStyles(props.style || {})();

  const events = useSelector<AppState, IEvent[]>((state) => state.app.events);
  let nextEvents = events.filter(d => new Date(d.startTime).getTime() - new Date().getTime() > 0);
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true });
  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'), { noSsr: true });
  //pour plus tard après le dev du medium
  // let sizeBackround = "?size=original"
  // if(isXs){
  //   sizeBackround = "?size=medium"
  // }
  let sizeLogo = "?size=medium"
  if(isXs){
    sizeLogo = "?size=small"
  }
  if(!events){
    return <div>Text slider_json not found</div>
  }
  
  const getStartime = (event: IEvent) => {
    let languages: Record<string,string> = {
      fr: 'fr-FR',
      en: 'en-US',
      ar: 'ar-AE'
    }
    const dateTest = new Date(event.startTime)
    const options:Intl.DateTimeFormatOptions = { weekday: "short", day: 'numeric', month: 'short', hour: 'numeric', minute: "numeric"};
    return dateTest.toLocaleDateString(languages[getCurrentLang()], options);
  }

  let slidesPerView = 1
  if(isLg){
    slidesPerView = 1.5
  }

  const params = {
    //virtual: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true
    },
    slidesPerView: slidesPerView,
    spaceBetween: 20,
    loop: true,
    speed: 450,
  };

  return (
      <div style={{ position: 'relative' }}>
        <div className={classes.containerSlider}>
          <Swiper {...params}
                    className={classes.swiper} >

            {nextEvents.map((slide, index) => {
              return (
                <React.Fragment>
                  <SwiperSlide virtualIndex={index} className={classes.swiperSlide} style={{backgroundImage: 'url(' + slide.visual + "?size=original"}} >
                      <Link to={`/category/${slide.category_slug}`} key={slide.category_slug}>
                        <div className={classes.dynamicInfoDiv}>
                          <img src={slide.category_title + sizeLogo} alt={slide.category_slug} className={classes.slideLogo} />
                          <div className={classes.slideMoreComponent}>
                            <Container maxWidth="lg" className={classes.info}>
                              <Typography variant="body2" className={classes.date} >
                                {getStartime(slide)}
                              </Typography>
                              <Typography component="div" variant="body1" className={classes.description}>
                                <CleverFormattedMessage id={slide.description} />
                              </Typography>
                            </Container>
                          </div>
                        </div>
                      </Link>
                  </SwiperSlide>
                </React.Fragment>
              )
            })}
          </Swiper>
        </div>
      </div>
  );
}