import React from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { CleverFormattedMessage, useFile } from 'videokube-front-library';
import { Divider}  from 'videokube-front-library/dist/Divider/Divider';
import { Container, Typography, useMediaQuery } from '@material-ui/core';
import { DisplayCategWithVideo } from '../../components/DisplayCategWithVideo';
import { VideoForYouDiv } from '../../components/VideoForYouDiv';
import { CounterBox } from '../../components/CounterBox';
import { categoryGrid, counterBoxStyle, DividerCenterStyle, DividerLeftStyle, keywordCloud, VideoForYou } from '../../theme';
import { KeywordsCloud } from '../../components/KeywordsCloud';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    categHeaderDiv: {
      height: '57%',
      background: 'linear-gradient(rgba(0, 0, 0, 0), #090909c7, #090909)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    sectionTwoCategTitle: {
      margin: '14em 0px 2em',
      [theme.breakpoints.down('sm')]: {
        margin: '5em 0px 1.5em',
      },
    },
    categoryImage: {
      height: '50vh',
      // paddingTop: '65px',
      minHeight: '250px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginBottom: '-20em',
      [theme.breakpoints.only('sm')]: {
        maxHeight: '480px',
        marginBottom: '-10em',
      },
      [theme.breakpoints.down('xs')]: {
        height: '20vh',
        minHeight: 'auto',
        marginBottom: '-4em',
      },
    },
    counterTitle: {
      marginTop: '2em',
    }
  }),
);

// const baseMd = 7;
// const getMd = (anIndex:number) => {
//     if([0,3].includes(anIndex % 4)){
//       return baseMd;
//     }
//     return 12 - baseMd;
// }

export function Category() {
  const theme = useTheme();
  const classes = useStyles();
  const header = useFile("categories_header");

  let sizeBackround = "?size=original"
  if (useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true })){
    sizeBackround = "?size=medium"
  }

  return (
    <div className={classes.root}>
      <div className={classes.categoryImage} style={{ backgroundImage: `url(${header.file+sizeBackround})` }}>
        <div className={classes.categHeaderDiv} ></div>
      </div>
      <DisplayCategWithVideo onlyCateg={false} style={categoryGrid(theme)} />
      <div className={classes.sectionTwoCategTitle} ></div>

      <Container maxWidth="lg">
        <Typography component="h3" variant="h3" >
          <CleverFormattedMessage id="filter_title" />
        </Typography>
        <Divider style={DividerLeftStyle(theme)}/>
      </Container>

      <KeywordsCloud style={keywordCloud(theme)} />

      <VideoForYouDiv style={VideoForYou(theme)}/>

      <Typography component="h2" variant="h2" className={classes.counterTitle} >
        <CleverFormattedMessage id="counter_title" />
      </Typography>
      <Divider style={DividerCenterStyle(theme)}/>

      <CounterBox style={counterBoxStyle(theme)}/>
    </div>
  );
}
