import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles, useTheme } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayArrow";
import { Link } from "react-router-dom";
import { DurationFormat } from "videokube-front-library/dist/utils";
import { useIntl } from "react-intl";
import { AddToPlaylistButton } from "./AddToPlaylist";
import { Media } from "videokube-front-library/dist/types";
import { AddToPlaylistStyle } from "../theme";

export interface SimpleVideoCardProps {
  media: Media
  backgroundMode?: boolean;
  height?: number;
  style?: any;
}

SingleVideoCard.defaultProps = {
  height: 200
};

export function SingleVideoCard(props: SimpleVideoCardProps) {
  const theme = useTheme();
  const classes = useStyles(props.style || {})();
  const { formatMessage } = useIntl();

  const renderCardMedia = () => {
    if (props.backgroundMode) {
      return (
        <CardMedia
          component="div"
          image={props.media.preview}
          style={{ height: props.height, width: "100%" }}
          className={classes.img}
        />
      );
    }
    return (
      <CardMedia
        component="img"
        alt={props.media.title}
        image={props.media.preview}
        title={props.media.title}
        className={classes.img}
      />
    );
  };

  return (
    <React.Fragment>
      <Card className={classes.card} raised={false}>
        <Link to={"/vod/" + props.media.slug} >
          <CardActionArea className={classes.svgPlayer}>
              {renderCardMedia()}
              <PlayCircleOutlineIcon className={classes.play} />
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.textRight}
              >
                {(() => {
                  if (props.media.duration) {
                    return DurationFormat(props.media.duration, formatMessage);
                  }
                  return;
                })()}
              </Typography>
          </CardActionArea>
        </Link>
      </Card>
      <Typography variant="h6" component="h4" className={classes.videoName}>
        {props.media.title}
      </Typography>
      <AddToPlaylistButton media={props.media} style={AddToPlaylistStyle(theme)}/>
    </React.Fragment>

  );
}

const useStyles = (extendsStyle: any) => makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxShadow: "none",
      borderRadius: "0px",
      [theme.breakpoints.between('sm', 'md')]: {
        maxWidth: '930px',
        margin: '0px auto',
      },
    },
    img: {
      height: 'auto',
      boxShadow: 'rgb(0 0 0 / 40%) 1px 4px 9px 3px',
      [theme.breakpoints.only('sm')]: {
        height: '410px !important',
        maxHeight: '95vh',
      },
      [theme.breakpoints.between('sm', 'xs')]: {
        height: '420px !important',
      },
      [theme.breakpoints.down('xs')]: {
        height: '200px !important',
      },
    },
    svgPlayer: {
      "&:hover": {
        "& svg": {
          opacity: 100,
          transition: "opacity 0.25s ease-in"
        },
      },
    },
    play: {
      position: "absolute",
      color: "white",
      fontSize: "5rem",
      bottom: 'calc(50% - 40px)',
      left: 'calc(50% - 40px)',
      [theme.breakpoints.down('md')]: {
        opacity: '30%',
      },
      [theme.breakpoints.up('md')]: {
        opacity: 0,
      },
    },
    textRight: {
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      borderRadius: '4px',
      position: 'absolute',
      bottom: '4px',
      right: '4px',
      padding: '2px',
    },
    textLeft: {
      backgroundImage: 'linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0))',
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      padding: '4px',
      color: 'white',
      paddingBottom: '20px',
    },
    videoName: {
      fontSize: '0.9em',
      paddingTop: '4px',
      fontWeight: '500',
    },
    ...extendsStyle
  })
);
