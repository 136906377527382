import { combineReducers } from 'redux';
import { videokubeUtils, store } from 'videokube-front-library';

export const defaultState = videokubeUtils.getDefaultState();

type State = typeof defaultState;

const appReducer = store.appRecucer;

const finalReducer = combineReducers({
  app: appReducer,
});

export type AppState = { app: State };
export { finalReducer };
