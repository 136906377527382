import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import { DisplayEvent } from '../components/DisplayEvent';
import {
  CleverFormattedMessage, useFile, utils,
} from 'videokube-front-library';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { IEvent, Media as IMedia } from 'videokube-front-library/dist/types';
import { SwiperMediaCategory } from '../components/SwiperMediaCategory';
import { getMedias } from 'videokube-front-library/dist/api';
import { DisplayCategWithVideo } from '../components/DisplayCategWithVideo';
import { VideoForYouDiv } from '../components/VideoForYouDiv';
import { categoryGrid,
  displayEvent,
  DividerFullStyle,
  DividerLeftStyle,
  fightCardDesign,
  sliderHeader,
  swiperVideo,
  VideoForYou } from '../theme';
import { SliderHeader } from '../components/SliderHeader';
import '../css/Homepage.css';
import {Divider} from 'videokube-front-library/dist/Divider/Divider';


const useStyles =  makeStyles((theme: Theme) =>
  createStyles({
    sliderDiv: {
      overflow: 'hidden',
      margin: 'auto',
      marginTop: '59px',
      [theme.breakpoints.down('md')]: {
        marginTop: '51px',
      },
    },
    titleH1: {
      textAlign: 'center',
      padding: '12px 0px 7px',
      backgroundColor: '#d10000',
      textShadow: '4px 4px 6px black',
      fontWeight: 600,
    },
    gradient: {
      height: '16vh',
      background: 'linear-gradient(#00000000, #090909)',
      [theme.breakpoints.up('sm')]: {
        height: '30vh',
      },
      [theme.breakpoints.only('sm')]: {
        height: '20vh',
      },
    },
    runningTextContainer: {
      maxWidth: '1230px',
      overflow: 'hidden',
      // [theme.breakpoints.up('sm')]: {
      //   marginTop: '40px',
      // },
    },
    newVideos: {
      marginTop: '3em',
      [theme.breakpoints.down('xs')]: {
        marginTop: '2em',
      },
    },
    sectionText: {
      display: 'flex',
    },
    gifContainer: {
      display: 'flex',
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        marginBottom: '6em',
      },
    },
    gifDiv: {
      width: '100%',
      height: 'auto',
      margin: '10px 0px',
      [theme.breakpoints.up('sm')]: {
        width: '49%',
        margin: '0px 10px',
      },
    },
  }),
);

export function Homepage() {
  const classes = useStyles();
  const theme = useTheme();
  const events = useSelector<AppState, IEvent[]>((state) => state.app.events);
  let nextEvents = events.filter(d => new Date(d.startTime).getTime() - new Date().getTime() > 0);

  const [latestMedias, setLatestMedias] = useState<IMedia[]>([])

  useEffect(() => {
    const latestMedias = async () => {
      let linkedMedias = await getMedias({ sense: "desc", limit: 10, live: 0 });
      setLatestMedias(linkedMedias);
    };
    latestMedias();
  }, []);

  const videoWebm = useFile("video_gif_home")
  const videoMp4 = useFile("video_gif_mp4")

  return (
    <div>
      <div className={classes.sliderDiv}>
        <Typography component="h1" variant="h3" className={classes.titleH1} >
          <CleverFormattedMessage id="homepage_title_v2" />
        </Typography>
        <SliderHeader style={sliderHeader(theme)}/>
      </div>
      <Container className={classes.runningTextContainer}>
        <Typography component="h2" variant="body1" className={utils.getCurrentLang() === 'ar' ? "runningTextRtl" : "runningText"} >
          <CleverFormattedMessage id="homepage_punchline" />
        </Typography>
      </Container>
        <Divider style={DividerFullStyle(theme)}/>
      <Container maxWidth="lg" className={classes.newVideos}>
        <Typography component="h2" variant="h3">
          <CleverFormattedMessage id="new_videos_title" />
          <Divider style={DividerLeftStyle(theme)}/>
        </Typography>
      </Container>
      <SwiperMediaCategory
        medias={latestMedias}
        designCard={fightCardDesign(theme)}
        maxWidth={1280}
        slideWidht={300}
        style={swiperVideo(theme)}
      />
      <DisplayEvent key="displayEvent" eventsList={nextEvents} style={displayEvent(theme)}/>
      <DisplayCategWithVideo onlyCateg={true} style={categoryGrid(theme)} />
      <VideoForYouDiv style={VideoForYou(theme)}/>
      <Container className={classes.gifContainer} maxWidth='lg'>
        <div className={classes.gifDiv}>
          <video autoPlay={true} loop={true} preload='auto' muted width="100%" playsInline>
            <source src={videoWebm.file} type="video/webm" />
            <source src={videoMp4.file} type="video/mp4" />
          </video>
        </div>
        <div className={classes.gifDiv}>
          <Typography component="h4" variant="h4" >
            <CleverFormattedMessage id="title_section_two" />
          </Typography>
          <CleverFormattedMessage id="homepage_section_two" />
        </div>
      </Container>
    </div>
  );
}
