import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, matchPath, Route, Switch, useLocation} from 'react-router-dom';
import { removeParamsFromParamString, getReactAppGTM, showPrivacyPolicy, showFaq, showContactPage, showWithdrawal, showImprint } from 'videokube-front-library/dist/videokubeUtils';
import {MuiThemeProvider, useTheme} from '@material-ui/core';
import {Homepage} from './pages/Homepage';
import {Topics} from './pages/Topics/Topics';
import {Category} from './pages/Category/Category';
import {Vod} from './pages/Videos/Vod';
import {About} from './pages/About';
import {Tv} from './pages/Videos/Tv';
import {Playlist} from './pages/Playlist';
import {
  api,
  foundSolution,
  SimpleRichText,
  TextsContext,
  useFile,
  useText,
  videokubeUtils
} from 'videokube-front-library';
import {
  DefaultTheme,
  getTheme,
  linkForgetPassword,
  navbarStyle,
  simpleTextNewStyle,
  translationComponent
} from './theme';
import zipObject from 'lodash/zipObject';
import {IntlProvider} from 'react-intl';
import {AppState, finalReducer} from './store';
import {createStore} from 'redux';
import {Provider, useSelector} from "react-redux";
import {IEvent, InterfaceConfiguration, IText, JwtPayload} from 'videokube-front-library/dist/types';
import {SnackbarProvider} from 'material-ui-snackbar-provider';
import './loader.css';
import './App.css';
import {Loader} from './components/Loader';
import ScrollToTop from './components/ScrollToTop';
import {getKeyWithPrefixIfExistant} from './utils/videokubeUtils';
import {LoginPage} from './pages/LoginPage/LoginPage';
import {Account} from './pages/Account/Account';
import {ThankYouPage} from './pages/ThankYou/index';
import { NavbarTranslationComponent } from './components/Navbar/TranslationComponents';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import moment from 'moment';
import { PlayListContextHandler } from './playlistContext';
import { flexibleGetEnv, getCurrentLang } from 'videokube-front-library/dist/utils';


const {getChannels, getVideoLink, getEvents, getFiles, getCategories, getTexts, getInterfaceConfiguration, pingMe, authTokenLogin, loginWithHE} = api;

const store = createStore(
  finalReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
);

const dispatch = store.dispatch;

const getAndSetTexts = async () => {
  const texts = await getTexts();
  dispatch({ type: 'SET_TEXTS', data: texts });
};

const getAndSetInterfaceConfiguration = async () => {
  const interfaceConfiguration = await getInterfaceConfiguration();
  dispatch({
    type: 'SET_INTERFACE_CONFIGURATION',
    data: interfaceConfiguration,
  });
};

const handleChannelsAndTvLink = async () => {
  try{
    const channels = await getChannels();
    dispatch({ type: 'SET_CHANNELS', data: channels });
  
    if (channels.length) {
      let channel = channels[0];
      const tvVideoLink = await getVideoLink(channel.id);
      dispatch({ type: 'SET_TV_VIDEO_LINK', data: tvVideoLink });
    }
  } catch(e){
    return;
  }

};

const getAndSetEvents = async () => {
  const events = await getEvents();
  //const events = eventData;
  let sortEvents = events.sort((a,b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
  dispatch({ type: 'SET_EVENTS', data: sortEvents });

}

const getAndSetFiles = async () => {
  const files = await getFiles();
  dispatch({ type: 'SET_FILES', data: files });
};
const authTokenLoginWrapper = async() => {
  const urlParams = new URLSearchParams(window.location.search);
  const authToken = urlParams.get("authtoken");
  if(!authToken){
    return;
  }
  return await authTokenLogin(authToken);
}

const checkHeader = () => {
  removeParamsFromParamString(window.location.search, ["mhe", "msisdnEncrypted"]);
}

const autoLogin = async () => {
  let msisdn = localStorage.getItem('msisdn');
  if (msisdn){
    const jwtPayload = await loginWithHE(msisdn);
    dispatch({ type: "SET_JWT_PAYLOAD", data: jwtPayload });
    return;
  }

  pingMe().then(aPayload => {
    if (!aPayload) {
      return;
    }
    dispatch({ type: 'SET_JWT_PAYLOAD', data: aPayload });
  }).catch(error => {
    //We catch the error : the user is simply not connected!
  })
}

const init = async () => {
  videokubeUtils.getReactAppGTM();
  try{
    const potentialAuthTokenPayload = await authTokenLoginWrapper();
    if(potentialAuthTokenPayload){
      //We redirect to origin ; we don't care if we were on /subscribe or whatever url. The user is now authenticated thanks to the jwt stored in local storage
      window.location.href = window.location.origin;
      return;
    }
  }catch(e){
    window.location.href = window.location.origin;
    return;
  }
  checkHeader();
  autoLogin();

  const arrayOfPromises: Array<any> = [
    getAndSetInterfaceConfiguration(),
    getAndSetTexts(),
    getCategories().then((categories) =>
      dispatch({ type: 'SET_CATEGORIES', data: categories }),
    ),
    handleChannelsAndTvLink(),
    getAndSetFiles(),
    getAndSetEvents()
  ];
  try {
    await Promise.all(arrayOfPromises);
    dispatch({ type: 'SET_READY', data: null });
  } catch (e) {
    //TODO: we should have an alert component ?
    console.log('ERROR while loading necessary data', e);
    //We don't set the page as ready... So it stays as "Loading" for ever. Intentional!
  }
};
init();

interface IRoute {
  name: string;
  component: string;
  path: string;
  withoutLayout?: boolean;
  allowAnonymous?: boolean;
  onlySubscriberUser?: boolean;
  title?: string;
}

const routesDb: IRoute[] = [
  //{ name: 'Contact', path: '/contact', component: 'Contact' },
  { name: 'About', path: '/about', component: 'About', allowAnonymous: true },
  //{ name: 'Login', path: '/login', component: 'Login' },
  { name: 'LoginPage', path: '/login', component: 'LoginPage', withoutLayout: true, allowAnonymous: true },
  // { name: 'SubscribePage', path: '/subscribe', component: 'SubscribePage', withoutLayout: true, allowAnonymous: true },
  //{ name: 'LandingPage', path: '/landing', component: foundSolution.getAppropriatePage(), allowAnonymous: true},
  { name: 'Homepage', path: '/', component: 'Homepage', allowAnonymous: true},
  { name: 'Topics', path: '/replay-center', component: 'Topics', allowAnonymous: true },
  { name: 'Category', path: '/category/:slug', component: 'Category', allowAnonymous: true },
  //{ name: 'CategoryEmpty', path: '/category/', component: 'Category', allowAnonymous: true },
  { name: 'Vod', path: '/vod/:slug', component: 'Vod', onlySubscriberUser: true },
  { name: 'Tv', path: '/tv', component: 'Tv', onlySubscriberUser: true, title: "replay_center" },
  { name: 'Garbage', path: '/garbage', component: 'Home' },
  { name: 'TermsConditions', path: '/terms-and-conditions', component: 'TermsConditions', allowAnonymous: true},
  //{ name: 'Schedule', path: '/schedule', component: 'Schedule', allowAnonymous: true },
  { name: 'Account', path: '/account', component: 'Account' },
  { name: 'ThankYou', path: '/thank-you', component: 'ThankYouPage', allowAnonymous: true},
  { name: 'PrivacyPolicy', path: '/privacy-policy', component: 'PrivacyPolicy', allowAnonymous: true },
  { name: 'Playlist', path: '/playlist', component: 'Playlist', allowAnonymous: true },
];


//This create a fake asynchronous routing (for instance coming from a backend server)
const getRoutesDb = async () => {
  return routesDb;
};

function App() {
  const [routes, setRoutes] = useState<IRoute[]>([]);

  const ready = useSelector<AppState, boolean>((state) => state.app.ready);
  const texts = useSelector<AppState, IText[]>((state) => state.app.texts);
  const jwtPayload = useSelector<AppState, JwtPayload>((state) => state.app.jwtPayload);

  const events = useSelector<AppState, IEvent[]>((state) => state.app.events);
  const liveEvent = events.find(
    anEvent => moment(anEvent.startTime).isAfter(moment().subtract(5, 'hour')) &&
      moment(anEvent.startTime).isBefore(moment())
  );

  const location = useLocation();

  const currentRoute = routes.find(
    route => matchPath(location.pathname,{exact:true,path:route.path})
  )

  const interfaceConfiguration = useSelector<AppState, InterfaceConfiguration>(
    (state) => state.app.interfaceConfiguration,
  );

  let theme = DefaultTheme;
  
  if(interfaceConfiguration){
    theme = getTheme(interfaceConfiguration);
  }

  useEffect(() => {
    getRoutesDb().then(someRoutes => setRoutes(someRoutes));
  }, []);

  const favicon = useFile("favicon");
  let link: any = document.querySelector('link[rel="icon"]');
  if(favicon && link){
    link.href = favicon.file;
  }

  const metaTitle = useText("meta_title");
  if(metaTitle && document){
    document.title = metaTitle.value;
  }

  const textsKeyValue = zipObject(
    texts.map(x => x.key),
    texts.map(x => x.value),
  );
  const textsKeyValueObject = zipObject(
    texts.map(x => x.key),
    texts,
  );

  let loggedInUser:string | null = null;
  let subscriberUser:boolean | null = null;

  if(jwtPayload){
    loggedInUser = jwtPayload.username;
    subscriberUser = jwtPayload.user.activeSubscription;
  }

  const logo = useFile("logo");

  if (!ready) {
    return(
      <Loader />
    );
  }
  
  if(!logo){
    return (<div>Logo not found</div>);
  }

  let linksLeft =   [{
    slug: '/tv',
    title: 'live',
  }]
  if (!liveEvent)
  {
    linksLeft.pop();
  }

  let forgottenPassword: Nullable<JSX.Element> = null;

  let accountSlug = {
    slug:'/account',
    title: 'account',
  }

  let linksMiddle =   [{
    slug: '/category',
    titleKey: 'leagues',
  }, {
    slug: '/playlist',
    titleKey: 'playlist',
  }
]


  let linksFooter = [
    {
      slug: '/about',
      title: 'footer_about_us_title',
      titleKey: 'footer_about_us_title',
    },
    {
      slug: '/terms-and-conditions',
      title: 'footer_terms_and_conditions_title',
      titleKey: 'footer_terms_and_conditions_title',
    },
  ];
  if(videokubeUtils.showPrivacyPolicy()) {
    linksFooter.push(
      {
        slug: '/privacy-policy',
        title: 'footer_privacy_policy_title',
        titleKey: 'footer_privacy_policy_title',
      }
    )
  }

  let authTokenSuffix = "";
  const potentialAuthToken = (jwtPayload?.user as any)?.authToken;
  if(potentialAuthToken){
    authTokenSuffix = `?authtoken=${potentialAuthToken}`;
  }





  const switchStuff = routes.map(aRoute => {
    const CurrentComponent = componentsDb[aRoute.component];

    let coreElement =  <CurrentComponent />
    if ((currentRoute?.onlySubscriberUser && subscriberUser) || (loggedInUser && !currentRoute?.onlySubscriberUser) || currentRoute?.allowAnonymous ){
    } else {
      if (foundSolution.getAppropriatePage() === "RedirectLandingPage") {
        window.location.href = foundSolution.getRedirectUrl();
        return(
          <Loader />
        );
      }
      window.location.href = window.location.origin + "/landing/";
    }


    return (
      <Route key={aRoute.path} exact path={aRoute.path}>
          <div style={{position: 'fixed', zIndex: 5, width: '100%'}}>
            <Navbar
              style={navbarStyle(theme)}
              logo={logo.file+"?size=original"}
              linksLeft={linksLeft}
              linksRight={[]}
              linksFooter={linksFooter}
              linksMiddle={linksMiddle}
              accountSlug={accountSlug}
              login
              loggedInUser={loggedInUser}
              //loginRedirectUrl={"/replay-center"}
              forgottenPassword={forgottenPassword}
              useLoginPageUrl={"/login"}
              langButton={true}
              translationComponent={<NavbarTranslationComponent style={translationComponent(theme)} />}
            />
          </div>
        {coreElement}
          <div>
            <Footer links={linksFooter} otherColor={{ color: theme.palette.primary.main, backgroundColor: theme.palette.secondary.main }} />
          </div>
      </Route>
    );
  });

  return (
    <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
      <TextsContext.Provider value={textsKeyValueObject}>
        <IntlProvider locale={getCurrentLang()} messages={textsKeyValue}>
          <MuiThemeProvider theme={theme}>
            <PlayListContextHandler>
              <ScrollToTop />
              <div className="App" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', direction: getCurrentLang() === 'ar' ? 'rtl' : 'ltr' }}> <Switch>{switchStuff}</Switch> </div>
            </PlayListContextHandler>
          </MuiThemeProvider>
        </IntlProvider>
      </TextsContext.Provider>
    </SnackbarProvider>
  );
}

const TermsConditions = () => {
  const theme = useTheme();
  const headerImage = useFile("terms_conditions_header");

  const values = useContext(TextsContext);

  const titleId = getKeyWithPrefixIfExistant(values,"terms_conditions_title");
  const contentId = getKeyWithPrefixIfExistant(values, "terms_conditions_content")

  return <SimpleRichText titleId={titleId} contentId={contentId} style={simpleTextNewStyle(theme)} headerImage={headerImage} />
};

const PrivacyPolicy = () => {
  const theme = useTheme();
  const headerImage = useFile("privacy_policy_header");

  const values = useContext(TextsContext);

  const titleId = getKeyWithPrefixIfExistant(values,"privacy_policy_title");
  const contentId = getKeyWithPrefixIfExistant(values, "privacy_policy_content");

  return <SimpleRichText titleId={titleId} contentId={contentId} style={simpleTextNewStyle(theme)} headerImage={headerImage} />
};

// const Contact = () => {
//   const theme = useTheme();
//   const headerImage = useFile("contact_header");

//   return <SimpleRichText titleId="contact_title" contentId="contact_content" style={simpleTextNewStyle(theme)} headerImage={headerImage} />
// };

const componentsDb: { [id: string]: () => JSX.Element } = {
  About,
  Homepage,
  Topics,
  Category,
  Vod,
  Tv,
  LoginPage,
  Account,
  TermsConditions,
  ThankYouPage,
  PrivacyPolicy,
  Playlist,
};
const FinalApp = () => <Provider store={store}>
  <Router>
    <App />
  </Router>
</Provider>

export default FinalApp;
