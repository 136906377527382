import React from 'react';
import { Button, ButtonGroup, createStyles, Hidden, makeStyles, Theme } from '@material-ui/core';
import {
  //MenuDropDown,
  useStylesNavbar,
  //dropdownRedirectGen
} from '.';
//import { useIntl } from 'react-intl';
import { getCurrentLang, getLangs } from 'videokube-front-library/dist/utils';

interface SlugTitle {
  slug: string;
  title?: string;
  titleKey?: string;
  customComponent?: JSX.Element;
}

interface NavbarTranslationComponentProps {
  style: any,
} 

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGroup: {
      color: 'blue',
      '& .MuiButtonGroup-grouped:not(:last-of-type)': {
        borderInlineEnd: '0.5px solid #D91535',
        borderRight: 'none',
      },
    },
  }))


export const NavbarTranslationComponent = (props: NavbarTranslationComponentProps) => {
  let classesNavbar = useStylesNavbar(props.style)();
  const classes = useStyles();
  //let classesNavbarMobile = useStylesNavbar(props.style,"auto")();
  //const { formatMessage } = useIntl();

  const getMenuDropDownBody = (mobileMode = false) => {
    const currentLang = getCurrentLang();
    const allLanguages = getLangs();

    const langComponent = (lang: string) => {
      const click = () => {
        localStorage.setItem('lang', lang);
        window.location.reload();
      }

      return (
        <Button style={{padding: '6px 16px'}}
        className={`${currentLang === lang ? classesNavbar.activeButton : classesNavbar.button}`}
        onClick={click}>{lang}</Button>
      )
    }

    // let mappedLangArray: SlugTitle[] = allLanguages
    //   .filter(lang => lang !== currentLang)
    //   .map(lang => ({
    //     slug: "",
    //     title: lang,
    //     customComponent: langComponent(lang)
    //   }));

    // const calculatedClasses = mobileMode ? classesNavbarMobile : classesNavbar;

    return <ButtonGroup variant="text" orientation="horizontal" color="primary" aria-label="text primary button group" className={classes.buttonGroup} >
      {allLanguages.map(x=>(langComponent(x)))}
    </ButtonGroup>
    // return <MenuDropDown
    //   noHoverMode={mobileMode}
    //   dropdown={{
    //     title: formatMessage({id: "lang_" + currentLang}),
    //     links: mappedLangArray,
    //   }}
    //   index={10}
    //   classes={calculatedClasses}
    //   dropdownRedirect={dropdownRedirectGen(calculatedClasses)}
    // />
  };

  return (
    <React.Fragment>
      {/* Desktop */}
      <Hidden smDown>{getMenuDropDownBody(false)}</Hidden>
      {/* Mobile */}
      <Hidden mdUp>{getMenuDropDownBody(true)}</Hidden>
    </React.Fragment>
  );
};