import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import {
  CleverFormattedMessage,
  useFile,
  Video,
} from 'videokube-front-library';
import { Container, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
//import moment from 'moment';
import { AppState } from '../../store';
//import { useHistory } from 'react-router-dom';
//import { Link } from 'react-router-dom';
import { //Category,
  IEvent, VideoLink, JwtPayload, Media } from 'videokube-front-library/dist/types';
import { api } from 'videokube-front-library';
import { eventData } from '../../data/data';
import { VideoInfo } from '../../components/VideoInfo';
import Typography from "@material-ui/core/Typography";
import { DisplayCategWithVideo } from '../../components/DisplayCategWithVideo';
import { DisplayEvent } from '../../components/DisplayEvent';
import { videoInfo } from '../../theme';

const { getVideoLink } = api;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingTop: '65px',
    },
    tvVideoBackground: {
      maxWidth: '1235px',
      margin: '0px auto',
      backgroundPosition: 'bottom',
      backgroundSize: 'cover',
      height: '320px',
      paddingBottom: '12em',
      marginBottom: '12vh',
      marginTop: '-11em',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      [theme.breakpoints.only('sm')]: {
        height: '280px',
        marginBottom: '0px',
        marginTop: '-7em',
      },
      [theme.breakpoints.between('sm', 'xs')]: {
        height: '340px',
        marginBottom: '4em',
      },
      [theme.breakpoints.down('xs')]: {
        height: '190px',
        marginBottom: '0px',
        paddingBottom: '5em',
        marginTop: '-4em',
      },
    },
    description: {
      lineHeight: '24px',
      marginTop: '40px',
    },
    verticalBar: {
      borderLeft: '2px solid #999999',
      marginRight: '10px',
    },
    placeholder: {
      background: '#f8f8f8',
    },
    tvReplay: {
      marginBottom: '1em',
      marginTop: '10em',
      [theme.breakpoints.down('sm')]: {
        marginTop: '4em',
      },
    },
    alert: {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
    },
  }),
);

export function Tv() {
  const classes = useStyles();
  // const categories = useSelector(
  //   (state: any) => state.app.categories,
  // ) as Category[];
  const theme = useTheme();
  const events = useSelector<AppState, IEvent[]>((state) => state.app.events);
  let nextEvents = events.filter(d => new Date(d.startTime).getTime() - new Date().getTime() > 0);
  const channels = useSelector<AppState, Media[]>((state) => state.app.channels);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoPreview, setVideoPreview] = useState<string>('');
  const jwtPayload = useSelector<AppState, JwtPayload>((state) => state.app.jwtPayload);

  // const liveEvent = events.find(
  //   anEvent => moment(anEvent.startTime).isAfter(moment().subtract(5, 'hour')) &&
  //   moment(anEvent.startTime).isBefore(moment())
  // );

  const liveEvent = eventData[0]

  //let history = useHistory();

  // if (!liveEvent) {
  //   history.push("/schedule");
  // }

  useEffect(() => {
    const getChannelLink = async () => {
      if (liveEvent && jwtPayload) {
        if (channels[0]) {
          setVideoUrl(null);
          setVideoPreview("");
          let videoInfo: VideoLink;
          try {
            videoInfo = await getVideoLink(channels[0].id);
            //videoInfo = await getVideoLink(840);
          } catch (e) {
            return;
          }
          setVideoUrl(videoInfo.link);
          setVideoPreview(liveEvent.visual);
        }
      }
    }
    getChannelLink();
  }, [channels, jwtPayload, liveEvent]);

  const picurePaper = useFile("paper_img");
  let sizeBackround = "?size=original"
  if (useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'), { noSsr: true })){
    sizeBackround = "?size=medium"
  }
  let picure = picurePaper.file + sizeBackround;

  return (
    <div className={classes.root}>
      {videoUrl && (
        <Container maxWidth="md">
          <Video video={videoUrl} poster={videoPreview} bigCenteredMode={true} />
        </Container>
      )}
      {/* TODO ca sert pas a grand chose ca  */}
      {/* {!jwtPayload && (
        <CustomSnackBar text={needLoginTitle} />
      )} */}
      <div className={classes.tvVideoBackground} style={{backgroundImage: `url(${picure})`}}>
        {liveEvent && (
          <Container maxWidth="md">
            <Typography variant="h6" component="h6">
              <CleverFormattedMessage id="current_live" />
            </Typography>
            <VideoInfo
              description={liveEvent.description} img={liveEvent.visual}
              style={videoInfo(theme)}
            />
          </Container>
        )}
      </div>
      <DisplayEvent key="displayEvent" eventsList={nextEvents} />
      <DisplayCategWithVideo onlyCateg={true} />
    </div>
  );
}
